import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CircularProgress from '@mui/material/CircularProgress';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useSiteDataApi } from '../utilities/SiteDataProvider';
const cookies = new Cookies();

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    key: index,
  };
}

const UpgradeReport = () => {
  const [value, setValue] = React.useState(parseInt(cookies.get('activeClinic')) || 0);

  const [siteDataContext] = useSiteDataApi();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set('activeClinic', newValue, { path: '/', secure: true, sameSite: 'none' });
  };

  const [referralData, setReferralData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchReferralData();
    }
    fetchData();
  }, []);

  const fetchReferralData = async () => {
    try {
      const res = await fetch('/api/three-year-insurance', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        //sort the data a bit
        // let referralDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   referralDataSortedByDate['location'] = referral.location;
        //   return null;
        // });
        // console.log('wee', referralDataSortedByDate);
        console.log('data', data);
        const grouped = groupBy(data.returnData[0], (upgrade) => upgrade.location);

        const referralDataSortedByDate = Array.from(grouped);
        console.log('referralDataSortedByDate', referralDataSortedByDate);
        const coolArray = [];
        referralDataSortedByDate.map((clinic) => {
          coolArray[clinic[0]] = clinic[1];
          return null;
        });
        setReferralData(coolArray);
        console.log('reference', coolArray);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Box m={3} className="referralPage">
      {'Chatham' in referralData ? (
        <>
          <AppBar position="static">
            <Tabs value={value} variant="fullWidth" onChange={handleChange} indicatorColor="secondary" textColor="inherit" aria-label="fullwidth tabs">
              {Object.keys(referralData).map((key, count) => {
                return <Tab label={key} {...a11yProps(count)} key={count} />;
              })}
            </Tabs>
          </AppBar>
          {Object.keys(referralData).map((key, count) => {
            return (
              <TabPanel value={value} index={count} key={count}>
                <div style={{ marginTop: '20px' }}>
                  <Typography style={{ textAlign: 'center' }} gutterBottom component="h6" variant="h6">
                    GreenShield and Ford Coverage (3 years)
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="referral table" className="referralTable">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell align="center" style={{ width: '13%' }}>
                            Days Until New Coverage
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '12%' }}>
                            Patient Name
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '15%' }}>
                            Date Purchased
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '15%' }}>
                            Device Purchased
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {referralData[key].map((referralDay, y) => {
                          // console.log('referralData', referralDay);
                          return (
                            <StyledTableRow
                              key={y}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center" className={referralDay.timeToPurchase < 30 ? 'notAllBooked' : ''}>
                                {referralDay.timeToPurchase}
                              </TableCell>
                              <TableCell align="center">
                                <Box>{referralDay.given_name + ' ' + referralDay.surname}</Box>
                              </TableCell>
                              <TableCell align="center">
                                {new Date(referralDay.purchase).toLocaleDateString('en-US', {
                                  // weekday: 'long',
                                })}
                              </TableCell>
                              <TableCell align="center">
                                <Box>{referralDay.model_name}</Box>
                              </TableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
            <br />
            <Typography style={{ textAlign: 'center' }}>Loading upgrade data...</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default UpgradeReport;
