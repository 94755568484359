import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Tooltip,
  // InputAdornment, Grid, TextField, Button
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

// import bodyParser from 'body-parser';

function replacer(key, value) {
  if (key === "source") return undefined;
  else return value;
}

var groupByTime = require("group-by-time");

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// let referralDataSortedByDate = [];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2000.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2000.99 to be printed as $2,501)
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    key: index,
  };
}

const HAOrderReport = () => {
  const [value, setValue] = React.useState(
    parseInt(cookies.get("activeClinic")) || 0
  );
  const [checked, setChecked] = React.useState(true);

  const [siteDataContext] = useSiteDataApi();

  const handleSwitchChange = (event, newValue) => {
    setChecked(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };

  const [referralData, setReferralData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchReferralData();
    }
    fetchData();
  }, []);

  const fetchReferralData = async () => {
    try {
      const res = await fetch("/api/ha-orders", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      let data = await res.json();

      if (data) {
        //sort the data a bit
        // let referralDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   referralDataSortedByDate['location'] = referral.location;
        //   return null;
        // });

        data.returnData[0] = data.returnData[0].map((order) => {
          return { ...order, order_date: order.order_date + "T21:51:18.000Z" };
        });
        console.log("wee", data);
        const grouped = groupBy(
          data.returnData[0],
          (referral) => referral.location
        );
        const referralDataSortedByDate = Array.from(grouped);

        // referralDataSortedByDate.forEach((office)=>{

        // });
        referralDataSortedByDate.push(["Total", data.returnData[0]]);

        // referralDataSortedByDate[referralDataSortedByDate.length-1][1].push()

        referralDataSortedByDate.map((clinic) => {
          clinic[2] = groupByTime(clinic[1], "order_date", "week");
          clinic[3] = groupByTime(clinic[1], "order_date", "day");
          return null;
        });

        //  awesomeClinicList['Total'] = [];
        //  Object.keys(awesomeClinicList).forEach((office) => {
        //    Object.keys(awesomeClinicList[office]).forEach((week) => {
        //      awesomeClinicList['Total'][week] = [];
        //      Object.keys(awesomeClinicList[office][week]).forEach((day) => {
        //        awesomeClinicList['Total'][week][day] = [];
        //        awesomeClinicList['Total'][week][day].push(awesomeClinicList[office][week][day]);
        //      });
        //    });
        //  });

        // let weeksArray = [];
        let awesomeClinicList = [];
        //loop through each clinic, Adelaide / Chatham etc.
        referralDataSortedByDate.map((clinic, clinicKey) => {
          //loop through the weeks to get first week
          // console.log('chad', clinic);
          // let clinicObj = {};

          // clinicObj.clinicName = clinic[0];
          awesomeClinicList[clinic[0]] = [];
          // Object.entries(clinic[2]).forEach((day, k) => {
          //   weeksArray.push(day);
          // });
          // // weeksArray.reverse();
          // // let firstWeekDate = new Date(parseInt(weeksArray[0][0]));
          // weeksArray = [];
          // // console.log('firstweekDate', firstWeekDate);
          // // awesomeClinicList[clinicKey].weeks = [];
          Object.entries(clinic[2]).map((workWeek) => {
            let week = workWeek[0];
            awesomeClinicList[clinic[0]][week] = [];

            // entry[1].map((innerEntry) => {
            // console.log('hokey', referralDataSortedByDate);

            Object.entries(clinic[3]).map((workDay) => {
              let Physician = "";
              let Directmail = "";
              let Advertising = "";
              let Formerpatient = "";
              let Patient = "";
              let Other = "";
              let CustomCount = 0;
              let SelectCount = 0;
              let PremiumCount = 0;
              let EliteCount = 0;
              let WSIBCount = 0;
              let VACODSPCount = 0;
              let unknownCount = 0;
              let AppointmentBookedCount = 0;
              let CustomStats = [];
              let SelectStats = [];
              let PremiumStats = [];
              let EliteStats = [];
              let WSIBStats = [];
              let VACODSPStats = [];
              let UnknownStats = [];
              let PatientsNoAppt = "";

              let workingDate = new Date(parseInt(workDay[0]));
              let workingWeek = new Date(parseInt(week));
              let dateDiff = (workingDate - workingWeek) / (1000 * 24 * 3600);
              // console.log('dateDiff', dateDiff);
              if (dateDiff < 7 && dateDiff >= 0) {
                awesomeClinicList[clinic[0]][week][workDay[0]] = [];
                workDay[1].map((order) => {
                  if (
                    order.insurers &&
                    (order.insurers.includes("ODSP") ||
                      order.insurers.includes("VAC") ||
                      order.insurers.includes("First Canadian Health (NIHB)"))
                  ) {
                    VACODSPCount++;
                    VACODSPStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else if (
                    order.insurers &&
                    order.insurers.includes("WSIB")
                  ) {
                    WSIBCount++;
                    WSIBStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else if (
                    (order.model_name.includes("12") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("1200") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("4") &&
                      order.vendor_name === "ReSound Corporation")
                  ) {
                    CustomCount++;
                    CustomStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else if (
                    (order.model_name.includes("16") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("1600") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("5") &&
                      order.vendor_name === "ReSound Corporation") ||
                    (order.model_name.includes("3") &&
                      order.vendor_name === "Signia")
                  ) {
                    SelectCount++;
                    SelectStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else if (
                    (order.model_name.includes("20") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("2000") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("7") &&
                      order.vendor_name === "ReSound Corporation") ||
                    (order.model_name.includes("5") &&
                      order.vendor_name === "Signia")
                  ) {
                    PremiumCount++;
                    PremiumStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else if (
                    (order.model_name.includes("24") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("2400") &&
                      order.vendor_name === "Starkey") ||
                    (order.model_name.includes("9") &&
                      order.vendor_name === "ReSound Corporation") ||
                    (order.model_name.includes("7") &&
                      order.vendor_name === "Signia")
                  ) {
                    EliteCount++;
                    EliteStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else if (order.model_name === "") {
                    VACODSPCount++;
                    VACODSPStats.push({
                      patient: order.given_name + " " + order.surname,
                      age: order.username,
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  } else {
                    unknownCount++;
                    UnknownStats.push({
                      source:
                        order.vendor_name.replace(" Corporation", "") +
                        " " +
                        order.model_name,
                    });
                  }
                  return null;
                });

                awesomeClinicList[clinic[0]][week][workDay[0]].PatientsNoAppt =
                  PatientsNoAppt;

                awesomeClinicList[clinic[0]][week][workDay[0]].push(workDay[1]);

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AppointmentBookedCount = AppointmentBookedCount;

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].PhysicianAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Physician =
                  Physician;
                awesomeClinicList[clinic[0]][week][workDay[0]].CustomCount =
                  CustomCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].CustomStats =
                  CustomStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].CustomStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].CustomStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].CustomStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]]
                        .CustomStats[key],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].PhysicianAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .PhysicianAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].PhysicianAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].CustomStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].CustomStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].DirectmailAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Directmail =
                  Directmail;
                awesomeClinicList[clinic[0]][week][workDay[0]].SelectCount =
                  SelectCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats =
                  SelectStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].DirectmailAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].DirectmailAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .DirectmailAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].DirectmailAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].SelectStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AdvertisingAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Advertising =
                  Advertising;
                awesomeClinicList[clinic[0]][week][workDay[0]].PremiumCount =
                  PremiumCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats =
                  PremiumStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].AdvertisingAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].AdvertisingAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .AdvertisingAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].AdvertisingAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].PremiumStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].FormerpatientAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Formerpatient =
                  Formerpatient;
                awesomeClinicList[clinic[0]][week][workDay[0]].EliteCount =
                  EliteCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats =
                  EliteStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].FormerpatientAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].FormerpatientAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .FormerpatientAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].FormerpatientAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].EliteStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].PatientAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Patient =
                  Patient;
                awesomeClinicList[clinic[0]][week][workDay[0]].WSIBCount =
                  WSIBCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats =
                  WSIBStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].PatientAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats[
                        key
                      ],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].PatientAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .PatientAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].PatientAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].WSIBStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][workDay[0]].OtherAgesString =
                  "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Other = Other;
                awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPCount =
                  VACODSPCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPStats =
                  VACODSPStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].OtherAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]]
                        .VACODSPStats[key],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].OtherAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .OtherAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].OtherAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].VACODSPStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });
              }
              return null;
            });
            return null;
          });
          return null;
        });

        setReferralData(awesomeClinicList);
        console.log("reference", awesomeClinicList);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  let {
    PhysicianAgg,
    DirectmailAgg,
    AdvertisingAgg,
    FormerpatientAgg,
    PatientAgg,
    OtherAgg,
    BookedAgg,
    DollarAgg,
  } = 0;
  let PatientsNoAppt = "";
  let {
    customAggTotal,
    SelectAggTotal,
    PremiumAggTotal,
    EliteAggTotal,
    WSIBAggTotal,
    VACODSPAggTotal,
    BookedAggTotal,
    DollarAggTotal,
  } = 0;

  return (
    <Box m={3} className="referralPage">
      {"Chatham" in referralData ? (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="fullwidth tabs"
            >
              {Object.keys(referralData).map((key, count) => {
                return <Tab label={key} {...a11yProps(count)} key={count} />;
              })}
            </Tabs>
          </AppBar>
          {Object.keys(referralData).map((key, count) => {
            return (
              <TabPanel value={value} index={count} key={count}>
                <Grid container alignItems="center" flexDirection="column">
                  <Grid item>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Summary</Typography>
                      <AntSwitch
                        checked={checked}
                        onChange={handleSwitchChange}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Weekly</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {Object.keys(referralData[key]).map((week, j) => {
                  // console.log('week', referralData[key][week]);
                  // console.log('week', week);
                  // console.log('week', Object.entries(referralData[key][week]));
                  if (Object.entries(referralData[key][week]).length) {
                    PhysicianAgg =
                      DirectmailAgg =
                      AdvertisingAgg =
                      FormerpatientAgg =
                      PatientAgg =
                      OtherAgg =
                      BookedAgg =
                      DollarAgg =
                        0;

                    PatientsNoAppt = "";
                    return (
                      <div
                        key={j}
                        style={{ marginTop: "20px" }}
                        className={`${checked ? "weekShow" : "weekHide"}`}
                      >
                        <Typography gutterBottom>
                          Week of{" "}
                          {new Date(parseInt(week)).toLocaleDateString()}
                        </Typography>
                        <TableContainer component={Paper} key={j}>
                          <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="referral table"
                            className="referralTable"
                          >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell style={{ width: "13%" }}>
                                  Date
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "12%" }}
                                >
                                  Custom
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "15%" }}
                                >
                                  Select
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Premium
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Elite
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  WSIB
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  VAC/ODSP/NIHB
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Total Orders
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Net
                                </StyledTableCell>
                                {/* <StyledTableCell align="center" style={{ width: '10%' }}>
                                  Referrals w/ Appts
                                </StyledTableCell> */}
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(referralData[key][week]).map(
                                (referralDay, y) => {
                                  let ordersDetails = referralDay[1];
                                  PhysicianAgg += ordersDetails["CustomCount"];
                                  DirectmailAgg += ordersDetails["SelectCount"];
                                  AdvertisingAgg +=
                                    ordersDetails["PremiumCount"];
                                  FormerpatientAgg +=
                                    ordersDetails["EliteCount"];
                                  PatientAgg += ordersDetails["WSIBCount"];
                                  OtherAgg += ordersDetails["VACODSPCount"];
                                  BookedAgg +=
                                    ordersDetails["AppointmentBookedCount"];
                                  PatientsNoAppt +=
                                    ordersDetails["PatientsNoAppt"];
                                  DollarAgg +=
                                    parseFloat(ordersDetails["CustomCount"]) *
                                      1038.75 +
                                    parseFloat(ordersDetails["SelectCount"]) *
                                      1705.5 +
                                    parseFloat(ordersDetails["PremiumCount"]) *
                                      2361.4 +
                                    parseFloat(ordersDetails["EliteCount"]) *
                                      2928.25 +
                                    parseFloat(ordersDetails["WSIBCount"]) *
                                      2000 +
                                    parseFloat(ordersDetails["VACODSPCount"]) *
                                      2000;
                                  return (
                                    <StyledTableRow
                                      key={y}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell>
                                        {referralDay[0] === 0
                                          ? 0
                                          : `${new Date(
                                              parseInt(referralDay[0])
                                            ).toLocaleDateString("en-US", {
                                              weekday: "long",
                                            })} (${new Date(
                                              parseInt(referralDay[0])
                                            ).toLocaleDateString()})`}
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            ordersDetails["CustomCount"] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  ordersDetails["CustomStats"],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")
                                                  .replaceAll("patient", "")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${ordersDetails["CustomCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {ordersDetails["CustomCount"] > 0
                                                ? `${ordersDetails[
                                                    "PhysicianAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            ordersDetails["SelectCount"] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  ordersDetails["SelectStats"],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")
                                                  .replaceAll("patient", "")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${ordersDetails["SelectCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {ordersDetails["SelectCount"] > 0
                                                ? `${ordersDetails[
                                                    "DirectmailAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            ordersDetails["PremiumCount"] >
                                            0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  ordersDetails["PremiumStats"],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")
                                                  .replaceAll("patient", "")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${ordersDetails["PremiumCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {ordersDetails["PremiumCount"] > 0
                                                ? `${ordersDetails[
                                                    "AdvertisingAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            ordersDetails["EliteCount"] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  ordersDetails["EliteStats"],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")
                                                  .replaceAll("patient", "")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${ordersDetails["EliteCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {ordersDetails["EliteCount"] > 0
                                                ? `${ordersDetails[
                                                    "FormerpatientAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            ordersDetails["WSIBCount"] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  ordersDetails["WSIBStats"],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")
                                                  .replaceAll("patient", "")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${ordersDetails["WSIBCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {ordersDetails["WSIBCount"] > 0
                                                ? `${ordersDetails[
                                                    "PatientAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            ordersDetails["VACODSPCount"] >
                                            0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  ordersDetails["VACODSPStats"],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")
                                                  .replaceAll("patient", "")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${ordersDetails["VACODSPCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {ordersDetails["VACODSPCount"] > 0
                                                ? `${ordersDetails[
                                                    "OtherAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        {ordersDetails["CustomCount"] +
                                          ordersDetails["SelectCount"] +
                                          ordersDetails["PremiumCount"] +
                                          ordersDetails["EliteCount"] +
                                          ordersDetails["WSIBCount"] +
                                          ordersDetails["VACODSPCount"]}
                                      </TableCell>

                                      <TableCell align="center">
                                        {formatter.format(
                                          ordersDetails["CustomCount"] *
                                            1038.75 +
                                            ordersDetails["SelectCount"] *
                                              1705.5 +
                                            ordersDetails["PremiumCount"] *
                                              2361.4 +
                                            ordersDetails["EliteCount"] *
                                              2928.25 +
                                            ordersDetails["WSIBCount"] * 2000 +
                                            ordersDetails["VACODSPCount"] * 2000
                                        )}
                                      </TableCell>
                                      {/* <TableCell align="center" className={ordersDetails['CustomCount'] + ordersDetails['SelectCount'] + ordersDetails['PremiumCount'] + ordersDetails['EliteCount'] + ordersDetails['WSIBCount'] + ordersDetails['VACODSPCount'] > ordersDetails['AppointmentBookedCount'] ? 'notAllBooked' : null}>
                                      <Tooltip enterTouchDelay={0} leaveTouchDelay={0} title={ordersDetails['CustomCount'] + ordersDetails['SelectCount'] + ordersDetails['PremiumCount'] + ordersDetails['EliteCount'] + ordersDetails['WSIBCount'] + ordersDetails['VACODSPCount'] > ordersDetails['AppointmentBookedCount'] ? <div style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{ordersDetails['PatientsNoAppt']}</div> : ''}>
                                        <Box>{ordersDetails['AppointmentBookedCount']}</Box>
                                      </Tooltip>
                                    </TableCell> */}
                                    </StyledTableRow>
                                  );
                                }
                              )}

                              <StyledTableRow className="totalRowAgg">
                                <TableCell className="totalRow">
                                  Total
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {PhysicianAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {DirectmailAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {AdvertisingAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {FormerpatientAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {PatientAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {OtherAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {PhysicianAgg +
                                    DirectmailAgg +
                                    AdvertisingAgg +
                                    FormerpatientAgg +
                                    PatientAgg +
                                    OtherAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {formatter.format(DollarAgg)}
                                </TableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    );
                  }
                  return null;
                })}
                <div
                  className={`totalTable ${checked ? "weekHide" : "weekShow"}`}
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="referral table"
                      className="referralTable referralTableTotal"
                    >
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell style={{ width: "13%" }}>
                            Week
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "12%" }}
                          >
                            Custom
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "15%" }}
                          >
                            Select
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Premium
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Elite
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            WSIB
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            VAC/ODSP/NIHB
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Total Orders
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Net
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(referralData[key]).map((week, j) => {
                          // console.log('week', referralData[key][week]);
                          customAggTotal =
                            SelectAggTotal =
                            PremiumAggTotal =
                            EliteAggTotal =
                            WSIBAggTotal =
                            VACODSPAggTotal =
                            DollarAggTotal =
                              0;

                          Object.entries(referralData[key][week]).map(
                            (referralDay, y) => {
                              // console.log(referralDay);
                              let ordersDetails = referralDay[1];
                              customAggTotal += ordersDetails["CustomCount"];

                              SelectAggTotal += ordersDetails["SelectCount"];

                              PremiumAggTotal += ordersDetails["PremiumCount"];

                              EliteAggTotal += ordersDetails["EliteCount"];

                              WSIBAggTotal += ordersDetails["WSIBCount"];

                              VACODSPAggTotal += ordersDetails["VACODSPCount"];

                              DollarAggTotal +=
                                parseFloat(ordersDetails["CustomCount"]) *
                                  1000 +
                                parseFloat(ordersDetails["SelectCount"]) *
                                  1500 +
                                parseFloat(ordersDetails["PremiumCount"]) *
                                  2000 +
                                parseFloat(ordersDetails["EliteCount"]) * 2800 +
                                parseFloat(ordersDetails["WSIBCount"]) * 2000 +
                                parseFloat(ordersDetails["VACODSPCount"]) *
                                  2000;
                              return null;
                            }
                          );
                          return (
                            <StyledTableRow className="totalRowAgg" key={j}>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {new Date(parseInt(week)).toLocaleDateString()}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {customAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {SelectAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {PremiumAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {EliteAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {WSIBAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {VACODSPAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {customAggTotal +
                                  SelectAggTotal +
                                  PremiumAggTotal +
                                  EliteAggTotal +
                                  WSIBAggTotal +
                                  VACODSPAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {formatter.format(DollarAggTotal)}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading HA Order data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HAOrderReport;
