import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Box, Grid, Button, Typography, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Calendar } from "react-date-range";
import { useSiteDataApi } from "../../../utilities/SiteDataProvider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import { isSameDay } from "date-fns";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const VacationRequest = ({ username, timeOffData }) => {
  const initialDays = [];
  const [siteDataContext] = useSiteDataApi();
  const [days, setDays] = React.useState(initialDays);
  const [vacationDays, setVacationDays] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedShadowDays, setSelectedShadowDays] = useState([]);
  const [dayAmount, setDayAmount] = useState(1);
  const [vacationRequests, setVacationRequests] = useState([]);
  const [adminVacationView, setAdminVacationView] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [futureVacation, setFutureVacation] = useState([]);
  const [slackChannel, setSlackChannel] = useState("");

  const [openSnack, setOpenSnack] = React.useState(false);

  useEffect(() => {
    //only keep the timeOffs that are for the current user
    // console.log("timeOffData", timeOffData);
    let userTimeOffs = timeOffData.vacation[0].filter(
      (timeOff) =>
        timeOff.username === username || timeOff.employee === username
    );

    // console.log("emplkoyees", timeOffData.employees);
    //also filter that timeOff.status is approved

    //get data on currently logged in employee. do this by fileringing timeOffData.employees by 'employee' and you must remove spaces from 'employee' and match it to username
    // console.log("timeOffData.employees[0]", timeOffData.employees[0]);
    // console.log("username", username);
    const currentUser = timeOffData.employees[0].filter(
      (employee) =>
        employee.username === username || employee.employee === username
    );

    const employeeStartDate = currentUser[0].startDate;
    setSlackChannel(currentUser[0].slackChannel);

    //group userTimeOffs by requestID
    const userTimeOffsByRequestID = _.groupBy(
      userTimeOffs,
      (timeOff) => timeOff.requestID
    );
    // console.log("userTimeOffsByRequestID", userTimeOffsByRequestID);
    //convert userTimeOffsByRequestID from object of objects to array of objects
    const userTimeOffsByRequestIDArray = Object.keys(
      userTimeOffsByRequestID
    ).map((key) => userTimeOffsByRequestID[key]);
    //sort userTimeOffsByRequestIDArray by date reversed
    userTimeOffsByRequestIDArray.sort((a, b) => {
      return new Date(b[0].date) - new Date(a[0].date);
    });
    // console.log("userTimeOffsByRequestIDArray", userTimeOffsByRequestIDArray);
    setVacationRequests(userTimeOffsByRequestIDArray);

    //create an array of years from every year since the employee's start date (employeeStartDate) until now
    const years = [];
    for (
      let year = new Date(employeeStartDate).getFullYear();
      year <= new Date().getFullYear();
      year++
    ) {
      years.push({ year, approved: 0, pending: 0 });
    }
    //reverse the years array
    years.reverse();
    //loop through each year and add up the approved and pending days
    const vacationDaysByYear = years.map((year) => {
      //loop through each requestID
      userTimeOffs.forEach((request) => {
        //loop through each day in the request

        //if the day is in the current year
        if (new Date(request.date).getFullYear() === year.year) {
          //if the request is approved
          if (request.status === "approved") {
            //add 1 to approved
            year.approved += parseFloat(request.full_half);
          } else {
            //add 1 to pending
            year.pending += parseFloat(request.full_half);
          }
        }
      });
      return year;
    });

    setVacationDays(vacationDaysByYear);
    //take data from timeOffData.vacation[0] and group it by employee. create a table for each year that looks like this:
    //employee |  current allowance | used
    //Taylor | 15 |  approved: 5  pending: 3
    //Taylor | 15 |  approved: 5  pending: 3

    const adminYears = [];
    //get the maxium year from any vacation request
    const maxYear = Math.max(
      ...timeOffData.vacation[0].map((request) => {
        return new Date(request.date).getFullYear();
      })
    );
    //get the minimum year from any vacation request
    const minYear = Math.min(
      ...timeOffData.vacation[0].map((request) => {
        return new Date(request.date).getFullYear();
      })
    );
    //loop through each year from minYear to maxYear and store it in adminYears. adminYears should look like this:
    // [{year:2022, employees:[{name:JoshuaWright, allowed:15, approved: 5, pending: 3}]}, {year:2023, employees:[{name:JoshuaWright, allowed:15, approved: 5, pending: 3}]}]
    for (let year = minYear; year <= maxYear; year++) {
      adminYears.push({ year, employees: [] });
    }
    //loop through each year in adminYears
    adminYears.forEach((year) => {
      //loop through each employee in timeOffData.employees[0]
      timeOffData.employees[0].forEach((employee) => {
        //create an object for each employee
        let employeeObject = {
          name: employee.employee,
          allowed: employee.vacationDays,
          approved: 0,
          pending: 0,
        };
        //loop through each request in timeOffData.vacation[0]
        timeOffData.vacation[0].forEach((request) => {
          //if the request is for the current year
          if (new Date(request.date).getFullYear() === year.year) {
            //if the request is for the current employee
            if (
              request.employee.toLowerCase() ===
              employee.employee
                .replace(
                  //remove spaces
                  /\s/g,
                  ""
                )
                .toLowerCase()
            ) {
              //if the request is approved
              if (request.status === "approved") {
                //add 1 to approved
                employeeObject.approved += parseFloat(request.full_half);
              } else {
                //add 1 to pending
                employeeObject.pending += parseFloat(request.full_half);
              }
            }
          }
        });
        //push the employeeObject to the employees array
        year.employees.push(employeeObject);
      });
    });
    //reverse adminYears so newest year is first
    adminYears.reverse();
    setAdminVacationView(adminYears);

    // console.log("userTimeOffsByRequestIDArray", userTimeOffsByRequestIDArray);

    const pendingRequests = userTimeOffsByRequestIDArray.filter(
      (request) => request[0].status === "pending"
    );

    setPendingRequests(pendingRequests);

    //get a list of all vacation requests from today onwards only. get only the date, employee name, and status of the request
    const vacationRequests = timeOffData.vacation[0].filter((request) => {
      return new Date(request.date) >= new Date().setHours(0, 0, 0, 0);
    });
    //group these vacationRequests by date, so if multiple employees have vacation on the same day it will group them together
    const vacationRequestsByDate = _.groupBy(
      vacationRequests,
      (request) => request.date
    );
    //vacationRequestsByDate is an object but it should be an array
    //convert vacationRequestsByDate from object of arrays to array of objects
    const vacationRequestsByDateArray = Object.keys(vacationRequestsByDate).map(
      (key) => vacationRequestsByDate[key]
    );
    // console.log("vacationRequestsByDateArray", vacationRequestsByDateArray);
    //sort vacationRequestsByDateArray by date, newest first
    vacationRequestsByDateArray.sort((a, b) => {
      return new Date(b[0].date) - new Date(a[0].date);
    });

    setFutureVacation(vacationRequestsByDateArray);
    // setVacationDays(vacationDaysByYear);
  }, [message]);

  const footer =
    selectedShadowDays && selectedShadowDays.length > 0 ? (
      <div>
        <p
          style={{
            textAlign: "center",
            border: "1px solid #999",
            margin: "3px 0",
          }}
        >
          You have selected{" "}
          {selectedShadowDays
            .map((day) => parseFloat(day.amount))
            .reduce((a, b) => a + b, 0)}{" "}
          day{selectedShadowDays.length > 1 ? "s" : ""}.
        </p>
      </div>
    ) : (
      <p style={{ textAlign: "center", border: "1px solid #999" }}>
        Please pick one or more days.
      </p>
    );
  const handleClose = () => {
    setOpen(false);
  };

  const handleDayClick = (day, modifiers) => {
    const newSelectedDays = [...selectedDays];
    const newSelectedShadowDays = [...selectedShadowDays];
    if (modifiers.selected) {
      const index = selectedDays.findIndex((selectedDay) =>
        isSameDay(day, selectedDay)
      );
      newSelectedDays.splice(index, 1);
      newSelectedShadowDays.splice(index, 1);
    } else {
      newSelectedDays.push(day);
      newSelectedShadowDays.push({ day, amount: parseFloat(dayAmount) });
    }
    // console.log("newSelectedDays", newSelectedDays);
    // console.log("newSelectedShadowDays", newSelectedShadowDays);
    setSelectedDays(newSelectedDays);
    setSelectedShadowDays(newSelectedShadowDays);
  };

  const requestVacation = async (selectedDays, message) => {
    // console.log("selectedDays", selectedDays);
    const totalDays = selectedShadowDays
      .map((day) => day.amount)
      .reduce((a, b) => a + b, 0);
    // console.log("requesting vacation");
    const requestID = uuidv4();
    // let staffRequesting = body.actions[0].value;
    // let dates = staffRequesting.split("||")[1];
    // let username = staffRequesting.split("||")[0];
    // let message = staffRequesting.split("||")[2];
    try {
      await axios
        .post(
          "/api/vacationRequest",
          JSON.stringify({ requestID, username, selectedDays, message }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("did it post?", res);
          if (res.data[0].insertId) {
            setDialog("Successfully recorded vacation days");
          }
        });
    } catch (error) {
      console.log(
        "error posting vacation request to internal tools server",
        error
      );
    }

    let slackMessage = `${totalDays} Vacation Day${
      totalDays <= 1 ? "" : "s"
    } Requested by ${username} for: \n${selectedShadowDays
      .map((day) => {
        return `${new Date(day.day).toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}${day.amount === 0.5 ? " (Half-day)" : ""}`;
      })
      // join the selectedDays above with a new line
      .join(`\n`)} \n\nMessage:\n"${message}"`;
    const messageArray = [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: slackMessage,
        },
      },
      {
        type: "actions",
        elements: [
          {
            type: "button",
            text: {
              type: "plain_text",
              text: "Confirm",
            },
            value: requestID,
            action_id: "vacay_day_confirm",
          },
        ],
      },
    ];

    await axios
      .post(
        "/api/timeoff-slack",
        JSON.stringify({
          channel: slackChannel,
          message: slackMessage,
          messageArray,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": siteDataContext.thhcsfrtoken,
          },
        }
      )
      .then(async (res) => {
        // console.log("slack response", res);
        if (res.data) {
          await axios
            .post(
              "/api/record-ts",
              JSON.stringify({
                requestID,
                slackTS: res.data,
                type: "vacation",
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": siteDataContext.thhcsfrtoken,
                },
              }
            )
            .then(() => {
              setOpenSnack(true);
            });
        }
      });

    setMessage("");
    setSelectedShadowDays([]);
    setSelectedDays([]);
  };
  const handleDayAmountChange = (e) => {
    setDayAmount(e.target.value);
  };
  return (
    //outside grid
    <Grid container spacing={2} direction="column">
      {username !== "TrueHearing Health" && username !== "TaylorField" ? (
        <Grid container spacing={2} direction="row">
          <Grid item container direction="column" sm={6}>
            <Typography
              variant="h4"
              textAlign="center"
              style={{ padding: "30px 0 0" }}
            >
              New Vacation Request
            </Typography>
            <Grid item container direction="row">
              <Grid
                item
                sm={12}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <DayPicker
                    mode="multiple"
                    min={1}
                    onDayClick={handleDayClick}
                    selected={selectedDays}
                    footer={footer}
                  />
                </Grid>
                <Grid item>
                  <FormControl>
                    {/* <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={dayAmount}
                      defaultValue={1}
                      onChange={handleDayAmountChange}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Full-Day"
                      />
                      <FormControlLabel
                        value={0.5}
                        control={<Radio />}
                        label="Half-day"
                      />
                    </RadioGroup> 
                    //create a radiogroup like above but have the buttons go beside each other instead of stacked vertically*/}
                    <RadioGroup
                      row
                      aria-label="dayAmount"
                      name="row-radio-buttons-group"
                      value={dayAmount}
                      defaultValue={1}
                      onChange={handleDayAmountChange}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Full-Day"
                      />
                      <FormControlLabel
                        value={0.5}
                        control={<Radio />}
                        label="Half-day"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                container
                sm={12}
                spacing={1}
                direction="column"
                alignItems="stretch"
                justifyContent="stretch"
              >
                <Grid item>
                  <TextField
                    multiline
                    sx={{ width: "100%" }}
                    rows={5}
                    variant="outlined"
                    placeholder="Enter a message about the vacation request to be sent to Taylor/Chad on Slack (optional)."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ width: "100%", height: "50px" }}
                    onClick={() => setOpen(true)}
                  >
                    {" "}
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sm={6} direction="column">
            <Grid item>
              <Typography
                variant="h4"
                textAlign="center"
                gutterBottom
                style={{ padding: "30px 0" }}
              >
                Vacation History
              </Typography>
            </Grid>
            <Grid item>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Year</StyledTableCell>
                    <StyledTableCell>Days Used</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {vacationDays.length &&
                    vacationDays.map((year, key) => {
                      return (
                        <StyledTableRow key={key}>
                          <StyledTableCell>{year.year}</StyledTableCell>
                          <StyledTableCell>
                            {year.approved}{" "}
                            {year.pending > 0
                              ? ` + ${year.pending} pending`
                              : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <br />
              <br />
            </Grid>
            <Grid item>
              {vacationRequests.length ? (
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Dates</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Date Requested</StyledTableCell>
                      <StyledTableCell>Date Approved</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {vacationRequests.length &&
                      vacationRequests.map((request, key) => {
                        return (
                          <StyledTableRow key={key}>
                            <StyledTableCell>
                              {request.map((day, key) => {
                                //return and format day.date as 2023-04-23, replace the bad one below
                                // return <div>{day.date}</div>;
                                return (
                                  <div key={key}>
                                    {new Date(day.date).toLocaleDateString(
                                      "en-US",
                                      {
                                        weekday: "short",
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      }
                                    )}
                                    {day.full_half === "0.5"
                                      ? " (Half-day)"
                                      : ""}
                                  </div>
                                );
                              })}
                            </StyledTableCell>
                            <StyledTableCell>
                              {request[0].status}
                            </StyledTableCell>
                            <StyledTableCell>
                              {new Date(
                                request[0].dateRequestSent
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </StyledTableCell>
                            <StyledTableCell>
                              {request[0].dateRequestConfirmed
                                ? new Date(
                                    request[0].dateRequestConfirmed
                                  ).toLocaleDateString("en-US", {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : // <Button variant="contained" color="primary">
                                  //   Cancel Request
                                  // </Button>
                                  null}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              ) : null}
            </Grid>
          </Grid>
          {/* //Right */}

          <div className="dialogs-and-stuff">
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Record Sick Day${
                selectedDays.length <= 1 ? "" : "s"
              }?`}</DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  Are you sure you want to request vacation for these dates?
                  <br />
                  <br />
                  {selectedShadowDays
                    .map((day) => {
                      return `${new Date(day.day).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}${day.amount === 0.5 ? " (Half-day)" : ""}`;
                    })
                    // join the selectedDays above with a new line
                    .join(`\n`)}
                  <p>
                    Total:{" "}
                    {selectedShadowDays
                      .map((day) => parseFloat(day.amount))
                      .reduce((a, b) => a + b, 0)}{" "}
                    day(s)
                  </p>
                  <br />
                  <strong>Message:</strong>
                  <br />
                  <br />"{message}"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    requestVacation(selectedShadowDays, message);
                    handleClose();
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              sx={{ width: "100%" }}
              open={openSnack}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={() => setOpenSnack(false)}
              // message="Vacation Request Sent"
              // action={action}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                Vacation Request Sent!
              </Alert>
            </Snackbar>
          </div>
        </Grid>
      ) : null}
      {username === "TrueHearing Health" || username === "TaylorField" ? (
        <Grid container item spacing={2} direction="row">
          <Grid container item direction="column" sm={6}>
            <Grid item>
              <Typography
                variant="h5"
                textAlign="center"
                gutterBottom
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                Pending Requests
              </Typography>
            </Grid>
            {pendingRequests.length ? (
              <Grid item>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Employee</StyledTableCell>
                      <StyledTableCell>Request Dates</StyledTableCell>
                      <StyledTableCell>Total Days</StyledTableCell>
                      <StyledTableCell>Date Requested</StyledTableCell>
                      <StyledTableCell>Message</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {pendingRequests.length &&
                      pendingRequests.map((request, key) => {
                        return (
                          <StyledTableRow key={key}>
                            <StyledTableCell>
                              {request[0].employee}
                            </StyledTableCell>
                            <StyledTableCell>
                              {request.map((day, key) => {
                                return (
                                  <div key={key}>
                                    {new Date(day.date).toLocaleDateString(
                                      "en-US",
                                      {
                                        weekday: "short",
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      }
                                    )}
                                    {day.full_half === "0.5"
                                      ? " (Half-day)"
                                      : ""}
                                  </div>
                                );
                              })}
                            </StyledTableCell>
                            <StyledTableCell>
                              {request
                                .map((day) => parseFloat(day.full_half))
                                .reduce((a, b) => a + b, 0)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {new Date(
                                request[0].dateRequestSent
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </StyledTableCell>
                            <StyledTableCell>
                              {request[0].message}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Grid>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                No pending requests
              </Typography>
            )}

            <Grid item>
              <Typography
                variant="h5"
                textAlign="center"
                gutterBottom
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                Upcoming Vacation
              </Typography>
            </Grid>
            {futureVacation && futureVacation.length > 0 ? (
              <Grid item>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Employee</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {futureVacation.length &&
                      futureVacation.reverse().map((date, key) => {
                        //if any of the dates are pending, add a class to the row
                        let pending = false;
                        date.forEach((day) => {
                          if (day.status === "pending") {
                            pending = "pendingrow";
                          }
                        });

                        return (
                          <StyledTableRow key={key} className={`${pending}`}>
                            <StyledTableCell>
                              {/* //print the date */}
                              {new Date(date[0].date).toLocaleDateString(
                                "en-US",
                                {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${
                                date.length > 1 ? "conflictvacay" : ""
                              }`}
                            >
                              {date.map((multiple, key) => {
                                return (
                                  <div key={key}>
                                    {multiple.employee}{" "}
                                    {multiple.full_half === "0.5"
                                      ? " (Half-day)"
                                      : ""}
                                    ({multiple.status})
                                  </div>
                                );
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Grid>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                No future vacations
              </Typography>
            )}
          </Grid>
          <Grid container item direction="column" sm={6}>
            <Grid item>
              <Typography
                variant="h5"
                textAlign="center"
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                Employee Vacation History
              </Typography>
            </Grid>
            <Grid item>
              {adminVacationView.length &&
                adminVacationView.map((year, key) => {
                  return (
                    <Grid item key={key}>
                      <Typography
                        variant="h6"
                        textAlign="center"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                        gutterBottom
                      >
                        {year.year}
                      </Typography>
                      <Table>
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Employee</StyledTableCell>
                            <StyledTableCell align="center">
                              {/* //print today's year like 2023 using a date */}
                              {new Date().getFullYear() + ` `}
                              Allowance
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Approved
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                              Pending
                            </StyledTableCell> */}
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {year.employees &&
                            year.employees.map((employee, key) => {
                              // console.log("employee", employee);
                              return (
                                <StyledTableRow key={key}>
                                  <StyledTableCell>
                                    {employee.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {employee.allowed}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {employee.approved}
                                    {employee.pending
                                      ? " + " + employee.pending + " pending"
                                      : ""}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align="center">
                                    {employee.pending}
                                  </StyledTableCell> */}
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default VacationRequest;
