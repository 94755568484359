import { Box, Typography, Button, Grid, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const ICSpickupScheduler = () => {
  const [submitSpinner, setSubmitSpinner] = useState("");
  const [submitSpinnerInteroffice, setSubmitSpinnerInteroffice] = useState("");
  // const [username, setUsername] = useState(cookies.get('ADPusername'));
  // const [password, setPassword] = useState(cookies.get('ADPpassword'));
  const defaultValues = {
    office: cookies.get("defaultOffice") || "",
    manufacturer: cookies.get("manufacturerPickup") || "",
  };
  const {
    register,
    handleSubmit,
    // formState: { errors },
    // control,
    // setError,
    // getValues,
    // setValue,
    // clearErrors,
  } = useForm({ defaultValues });

  // 114041290;
  // N5Y 5K7;

  const [siteDataContext] = useSiteDataApi();

  const onSubmit = async (data) => {
    const bodyData = data;
    // data.preventDefault();
    cookies.set("defaultOffice", data.office, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
    cookies.set("manufacturerPickup", data.manufacturer, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
    // bodyData.username = cookies.get('username');
    bodyData.username = "TrueHearingHealth";

    setSubmitSpinner("Requesting next day pickup...");

    // cookies.set('ICSusername', username, { path: '/', secure: true, sameSite: 'none' });
    // cookies.set('ICSpassword', password, { path: '/', secure: true, sameSite: 'none' });
    try {
      console.log("sending this", bodyData);
      const res = await fetch("/api/ICS-request", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
        body: JSON.stringify(bodyData),
      });

      const data = await res.json();

      if (data) {
        // if (data === 'Error requesting pickup, please submit manually.') {
        //   console.log('got data', data);
        setSubmitSpinner(data);
        // }
      }
    } catch (error) {
      console.log("error requesting pickup", error);
      // setSubmitResult('Error requesting pickup, please submit manually.');
    }
  };
  const onSubmitInteroffice = async (data) => {
    const bodyData = data;
    // data.preventDefault();
    cookies.set("defaultOffice", data.sending, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
    cookies.set("receiving", data.receiving, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
    // bodyData.username = cookies.get('username');
    bodyData.username = "TrueHearingHealth";

    setSubmitSpinnerInteroffice("Requesting interoffice shipment...");

    // cookies.set('ICSusername', username, { path: '/', secure: true, sameSite: 'none' });
    // cookies.set('ICSpassword', password, { path: '/', secure: true, sameSite: 'none' });
    try {
      console.log("sending this", bodyData);
      const res = await fetch("/api/ICS-request-interoffice", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
        body: JSON.stringify(bodyData),
      });

      const data = await res.json();

      if (data) {
        // if (data === 'Error requesting pickup, please submit manually.') {
        //   console.log('got data', data);
        setSubmitSpinnerInteroffice(data);
        // }
      }
    } catch (error) {
      console.log("error requesting interoffice shipment", error);
      // setSubmitResult('Error requesting pickup, please submit manually.');
    }
  };

  return (
    <Grid m={3} container>
      <Grid item sm={6}>
        <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <Typography
            textAlign="center"
            variant="h4"
            gutterBottom
            style={{ marginBottom: "30px" }}
          >
            Manufacturer Pickup
          </Typography>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={2}></Grid>
            <Grid item container justifyContent="space-between" sm={8}>
              <Grid item xs={5} sm={6}>
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                  <label htmlFor="login">Clinic Requesting Pickup</label>
                </div>
              </Grid>
              <Grid item xs={7} sm={6} className="selectbox">
                <select {...register("office")}>
                  <option value=""></option>
                  <option value="brantford">Brantford</option>
                  <option value="chatham">Chatham</option>
                  <option value="london">London</option>
                  <option value="milton">Milton</option>
                  <option value="sarnia">Sarnia</option>
                  <option value="windsor">Windsor</option>
                </select>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item container justifyContent="space-between" sm={8}>
              <Grid item xs={5} sm={6}>
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                  <label htmlFor="login">Sending Package To</label>
                </div>
              </Grid>
              <Grid item xs={7} sm={6} className="selectbox">
                <select {...register("manufacturer")}>
                  <option value=""></option>
                  <option value="resound">Resound</option>
                  <option value="starkey">Starkey</option>
                  <option value="bernafon">Bernafon</option>
                  <option value="diatec">Diatec</option>
                  <option value="oticon">Oticon</option>
                  <option value="phonak">Phonak</option>
                  <option value="signia">Signia</option>
                  <option value="unitron">Unitron</option>
                  <option value="widex">Widex</option>
                </select>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item container justifyContent="space-between" sm={8}>
              <Grid item xs={5} sm={6}></Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ width: "100%" }}
                  endIcon={<Box />}
                >
                  Request Next Day Pickup
                </Button>
                {submitSpinner ? (
                  <Box m={3}>
                    <div style={{ textAlign: "center" }}>
                      {submitSpinner ===
                      "Requesting next available pickup..." ? (
                        <CircularProgress />
                      ) : null}

                      <br />
                      <Typography>{submitSpinner}</Typography>
                      {submitSpinner ===
                      "Error requesting pickup, please submit manually." ? (
                        <Button
                          className="warning"
                          style={{ marginTop: "20px" }}
                          href="https://www.icscourier.ca/online-services/pickupondemand.aspx"
                          variant="contained"
                          target="_blank"
                        >
                          ICS Website for Manual Request
                        </Button>
                      ) : null}
                    </div>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </form>
      </Grid>
      {/* <Grid item sm={6}>
        <Typography
          textAlign="center"
          variant="h4"
          gutterBottom
          style={{ marginBottom: "30px" }}
        >
          Inter-office Shipment
        </Typography>
        <form autoComplete="on" onSubmit={handleSubmit(onSubmitInteroffice)}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={2}></Grid>
            <Grid item container justifyContent="space-between" sm={8}>
              <Grid item xs={5} sm={6}>
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                  <label htmlFor="login">Sending Clinic</label>
                </div>
              </Grid>
              <Grid item xs={7} sm={6} className="selectbox">
                <select {...register("sending")}>
                  <option value=""></option>
                  <option value="brantford">Brantford</option>
                  <option value="chatham">Chatham</option>
                  <option value="london">London</option>
                  <option value="sarnia">Sarnia</option>
                  <option value="windsor">Windsor</option>
                </select>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item container justifyContent="space-between" sm={8}>
              <Grid item xs={5} sm={6}>
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                  <label htmlFor="login">Receiving Clinic</label>
                </div>
              </Grid>
              <Grid item xs={7} sm={6} className="selectbox">
                <select {...register("receiving")}>
                  <option value=""></option>
                  <option value="brantford">Brantford</option>
                  <option value="chatham">Chatham</option>
                  <option value="london">London</option>
                  <option value="sarnia">Sarnia</option>
                  <option value="windsor">Windsor</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item mt={5}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ width: "100%" }}
                  endIcon={<Box />}
                >
                  Request Shipment
                </Button>
                {submitSpinnerInteroffice ? (
                  <Box m={3}>
                    <div style={{ textAlign: "center" }}>
                      {submitSpinnerInteroffice ===
                      "Requesting next available pickup..." ? (
                        <CircularProgress />
                      ) : null}

                      <br />
                      <Typography>{submitSpinnerInteroffice}</Typography>
                      {submitSpinnerInteroffice ===
                      "Error requesting pickup, please submit manually." ? (
                        <Button
                          className="warning"
                          style={{ marginTop: "20px" }}
                          href="https://www.icscourier.ca/online-services/pickupondemand.aspx"
                          variant="contained"
                          target="_blank"
                        >
                          ICS Website for Manual Request
                        </Button>
                      ) : null}
                    </div>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
          </Grid>
        </form>
      </Grid> */}
    </Grid>
  );
};

export default ICSpickupScheduler;
