import React, { useState, useEffect } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Box, Grid, Button, Typography, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Calendar } from "react-date-range";
import { useSiteDataApi } from "../../../utilities/SiteDataProvider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { v4 as uuidv4 } from "uuid";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SickDays = ({ username, timeOffData }) => {
  const [step, setStep] = useState(0);
  const [siteDataContext] = useSiteDataApi();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [sickDays, setSickDays] = React.useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [requestDate, setRequestDate] = useState({});
  const [dialog, setDialog] = useState("");
  const [dayAmount, setDayAmount] = useState("1");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [currentYearSickDays, setCurrentYearSickDays] = useState(0);
  const [employeeSickAllowance, setEmployeeSickAllowance] = useState(0);
  const [adminSickDayView, setAdminSickDayView] = useState([]);
  const [slackChannel, setSlackChannel] = useState("");
  const handleClickOpen = (e) => {
    setRequestDate(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const today = new Date().toISOString().slice(0, 19).replace("T", " ");
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow = tomorrow.toISOString().slice(0, 19).replace("T", " ");

  useEffect(() => {
    // console.log("uh waht", timeOffData);
    //only keep the timeOffs that are for the current user
    const userTimeOffs = timeOffData.sick[0].filter(
      (timeOff) =>
        timeOff.employee === username.replace(/%20/g, "") ||
        timeOff.username === username.replace(/%20/g, "")
    );
    // console.log("username", username);
    const currentUser = timeOffData.employees[0].filter(
      (employee) =>
        employee.username === username.replace(/%20/g, "") ||
        employee.employee === username.replace(/%20/g, "")
    );

    // setSlackChannel(
    //   currentUser[0].slackChannel
    // );
    console.log("currentUser[0]", currentUser[0]);
    // check if currentUser[0].slackChannel exists
    if (currentUser[0] && currentUser[0].slackChannel) {
      setSlackChannel(currentUser[0].slackChannel);
    } else {
      setSlackChannel("C04M757AW3A");
    }

    // console.log("currentUser", currentUser);
    setEmployeeSickAllowance(
      currentUser[0].sickDays ? currentUser[0].sickDays : 0
    );

    //do exactly but below but check that timeOff.status === 'approved'
    // const sickDaysByYear = userTimeOffs.reduce(
    //   (total, timeOff) =>
    //     total[new Date(timeOff.startDateTime).getFullYear()]
    //       ? {
    //           ...total,
    //           [new Date(timeOff.startDateTime).getFullYear()]:
    //             total[new Date(timeOff.startDateTime).getFullYear()] +
    //             parseFloat(timeOff.full_or_half),
    //         }
    //       : {
    //           ...total,
    //           [new Date(timeOff.startDateTime).getFullYear()]: parseFloat(
    //             timeOff.full_or_half
    //           ),
    //         },
    //   {}
    // );
    const sickDaysByYear = timeOffData.sick[0].reduce(
      (total, timeOff) =>
        timeOff.status === "approved" &&
        (timeOff.username === username || timeOff.employee === username)
          ? total[new Date(timeOff.startDateTime).getFullYear()]
            ? {
                ...total,
                [new Date(timeOff.startDateTime).getFullYear()]:
                  total[new Date(timeOff.startDateTime).getFullYear()] +
                  parseFloat(timeOff.full_or_half),
              }
            : {
                ...total,
                [new Date(timeOff.startDateTime).getFullYear()]: parseFloat(
                  timeOff.full_or_half
                ),
              }
          : total,
      {}
    );

    const sickDaysByYearAdmin = timeOffData.sick[0].reduce(
      (total, timeOff) =>
        timeOff.status === "approved"
          ? total[new Date(timeOff.startDateTime).getFullYear()]
            ? {
                ...total,
                [new Date(timeOff.startDateTime).getFullYear()]:
                  total[new Date(timeOff.startDateTime).getFullYear()] +
                  parseFloat(timeOff.full_or_half),
              }
            : {
                ...total,
                [new Date(timeOff.startDateTime).getFullYear()]: parseFloat(
                  timeOff.full_or_half
                ),
              }
          : total,
      {}
    );
    // console.log("sickDaysByYearAdmin", sickDaysByYearAdmin);

    //get the sick days used in the current year
    const sickDaysThisYear = sickDaysByYear[new Date().getFullYear()];
    setCurrentYearSickDays(sickDaysThisYear);
    setSickDays(sickDaysByYear);

    const adminSickDayView = [];
    //get a list of all the years that have sick days
    const years = Object.keys(sickDaysByYearAdmin);
    //get a list of all employees, and their startDate and sickDays allowance
    const employees = timeOffData.employees[0];
    // console.log("employees", employees);
    //loop through each year
    for (const year of years) {
      //loop through each employee
      for (const employee of employees) {
        //if the employee started before the year, add them to the list
        if (new Date(employee.startDate).getFullYear() <= year) {
          //count the number of sick days the employee has taken in the year
          //do it like but below but you must take the total of full_or_half because some days are half days, "0.5" (must be converted from string) or "1" (must be converted from string), add those together to get sick days used
          // const sickDays = timeOffData.sick[0].filter(
          //   (timeOff) =>
          //     timeOff.employee === employee.employee &&
          //     new Date(timeOff.startDateTime).getFullYear() ===
          //       parseInt(year, 10)
          // ).length;
          // console.log("sickDays");
          const sickDays = timeOffData.sick[0].reduce(
            (total, timeOff) =>
              timeOff.employee.toLowerCase() ===
                employee.employee
                  .replace(
                    //remove spaces
                    /\s/g,
                    ""
                  )
                  .toLowerCase() &&
              new Date(timeOff.startDateTime).getFullYear() ===
                parseInt(year, 10)
                ? total + parseFloat(timeOff.full_or_half)
                : total,
            0
          );
          //add the employee to the list
          adminSickDayView.push({
            employee: employee.employee,
            startDate: employee.startDate,
            allowed: employee.sickDays,
            sickDays,
            year,
          });
        }
      }
    }
    // console.log("adminSickDayView", adminSickDayView);
    //group adminSickDayView by year
    const adminSickDayViewByYear = _.groupBy(adminSickDayView, (employee) =>
      new Date(employee.year).getFullYear()
    );
    //adminSickDayViewByYear is an object, convert to array of objects
    const adminSickDayViewByYearArray = Object.keys(adminSickDayViewByYear)
      .map((year) => {
        return {
          year,
          employees: adminSickDayViewByYear[year],
        };
      })
      .reverse();
    // console.log("adminSickDayViewByYearArray", adminSickDayViewByYearArray);
    //reverse it
    setAdminSickDayView(adminSickDayViewByYearArray);
  }, []);

  const addSickDay = async (startDateTime = today, dayAmount, message) => {
    const sickID = uuidv4();
    let slackMessage = `${
      dayAmount === "1" ? "Full" : "Half"
    } Sick Day Requested for ${new Date(startDateTime).toLocaleDateString(
      "en-US",
      {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    )} by ${username}:\n${message}`;
    const messageArray = [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: slackMessage,
        },
      },
      {
        type: "actions",
        elements: [
          {
            type: "button",
            text: {
              type: "plain_text",
              text: "Confirm",
            },
            value:
              username +
              "||" +
              new Date(startDateTime)
                .toISOString()
                .slice(0, 19)
                .replace("T", " ") +
              "||" +
              message +
              "||" +
              dayAmount +
              "||" +
              sickID,
            action_id: "sick_day_confirm",
          },
        ],
      },
    ];
    await axios
      .post(
        "/api/timeoff-slack",
        JSON.stringify({
          channel: slackChannel,
          message: slackMessage,
          messageArray,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": siteDataContext.thhcsfrtoken,
          },
        }
      )
      .then(async (res) => {
        // console.log("slack response", res);

        try {
          await axios
            .post(
              "/api/sickRequest",
              JSON.stringify({
                sickID,
                username,
                startDateTime,
                message,
                dayAmount,
                slackTS: res.data, //this is the slack TS id
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              // console.log("did it post?", res);
              if (res.data[0].insertId === 0) {
                // setDialog("Successfully recorded vacation days");
                setOpenSnack(true);
              }
            });
        } catch (error) {
          console.log(
            "error posting vacation request to internal tools server",
            error
          );
        }
      });

    setStep(0);
    setMessage("");
  };
  const anotherDay = () => {
    setStep(1);
  };

  const handleDayAmountChange = (e) => {
    setDayAmount(e.target.value);
  };

  return (
    <Grid container>
      {username !== "TrueHearing Health" && username !== "TaylorField" ? (
        <Grid container item>
          <Grid item sm={6} container>
            <Grid item sm={12}>
              <Typography gutterBottom variant="h5" textAlign="center">
                Record Sick Day
              </Typography>
              {currentYearSickDays > 0 && employeeSickAllowance ? (
                <Typography gutterBottom textAlign="center">
                  You have used {currentYearSickDays} of {employeeSickAllowance}{" "}
                  sick days this year
                </Typography>
              ) : null}
            </Grid>
            {step === 0 ? (
              <Grid
                container
                item
                alignContent="center"
                direction="column"
                justifyContent="center"
              >
                <Grid
                  container
                  item
                  alignContent="center"
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item sm={12} m={2}>
                    <TextField
                      multiline
                      sx={{ margin: 0 }}
                      rows={4}
                      fullWidth
                      variant="outlined"
                      placeholder="Enter a message about the sick day to be sent to Taylor/Chad on Slack (optional)."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  alignContent="center"
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={dayAmount}
                        defaultValue="1"
                        row
                        onChange={handleDayAmountChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Full-Day"
                        />
                        <FormControlLabel
                          value="0.5"
                          control={<Radio />}
                          label="Half-Day"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  m={4}
                  spacing={1}
                  container
                  item
                  alignContent="center"
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setRequestDate(new Date());
                        setOpen(true);
                      }}
                    >
                      Today
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={() => anotherDay()}>
                      Another Day
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : step === 1 ? (
              <Grid
                m={4}
                spacing={1}
                container
                item
                alignContent="center"
                direction="row"
                justifyContent="center"
              >
                <Grid item sm={12}>
                  <Button
                    onClick={() => {
                      setStep(0);
                    }}
                    variant="contained"
                  >
                    Go Back
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <DayPicker
                    mode="single"
                    onDayClick={handleClickOpen}
                    selected={selectedDays}
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                No sick days record.
              </Typography>
            )}
          </Grid>
          <Grid item sm={6}>
            <Typography gutterBottom variant="h5" textAlign="center">
              Sick Days for {username}
            </Typography>
            {timeOffData ? (
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <TableContainer>
                    <Table
                      size="small"
                      aria-label="referral table"
                      className="referralTable"
                    >
                      <TableHead>
                        <StyledTableRow key="heade">
                          <StyledTableCell align="center">
                            Sick Days
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>

                      <TableBody>
                        {timeOffData &&
                          timeOffData.sick &&
                          timeOffData.sick.length > 0 &&
                          timeOffData.sick[0]
                            .sort((a, b) => {
                              return (
                                new Date(b.startDateTime) -
                                new Date(a.startDateTime)
                              );
                            })
                            .map((timeOff, count) => {
                              if (
                                (timeOff.employee === username ||
                                  timeOff.username === username) &&
                                timeOff.status === "approved"
                              ) {
                                return (
                                  <StyledTableRow key={count}>
                                    <StyledTableCell align="center">
                                      {new Date(
                                        timeOff.startDateTime
                                      ).toLocaleDateString("en-US", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              }
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={6}>
                  <TableContainer>
                    <Table
                      size="small"
                      aria-label="referral table"
                      className="referralTable"
                    >
                      <TableHead>
                        <StyledTableRow key="header">
                          <StyledTableCell align="center">Year</StyledTableCell>
                          <StyledTableCell align="center">
                            Days Used
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>

                      <TableBody>
                        {Object.keys(sickDays)
                          .sort((a, b) => b - a)
                          //filter sickDays so only if employee === username
                          .filter((year) => {
                            return (
                              timeOffData.sick[0].filter(
                                (timeOff) =>
                                  (timeOff.employee === username ||
                                    timeOff.username === username) &&
                                  timeOff.status === "approved" &&
                                  new Date(
                                    timeOff.startDateTime
                                  ).getFullYear() === parseInt(year, 10)
                              ).length > 0
                            );
                          })
                          .map((year, count) => {
                            return (
                              <StyledTableRow key={count}>
                                <StyledTableCell align="center">
                                  {year}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {sickDays[year]}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  width: "100%",
                  paddingTop: "30px",
                }}
              >
                No sick days record.
              </Typography>
            )}
          </Grid>
          <div id="snackanddialog">
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Record Sick Day?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to record a{" "}
                  {dayAmount === "1" ? "full" : "half"} sick day for{" "}
                  {new Date(requestDate).toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                  ?<br />
                  <br />
                  <strong>Message:</strong>
                  <br />
                  <br />"{message}"
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    addSickDay(
                      new Date(requestDate)
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " "),
                      dayAmount,
                      message
                    );
                    handleClose();
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              sx={{ width: "100%" }}
              open={openSnack}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={() => setOpenSnack(false)}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                Sick day notice sent!
              </Alert>
            </Snackbar>
          </div>
        </Grid>
      ) : (
        <Grid item container direction="row" sm={12} spacing={3}>
          {adminSickDayView.length &&
            adminSickDayView.map((year, key) => {
              return (
                <Grid item key={key}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                    gutterBottom
                  >
                    {year.year}
                  </Typography>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Employee</StyledTableCell>
                        <StyledTableCell align="center">
                          Sick Days Used
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                              Pending
                            </StyledTableCell> */}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {year.employees &&
                        year.employees.map((employee, key) => {
                          // console.log("employee", employee);
                          return (
                            <StyledTableRow key={key}>
                              <StyledTableCell>
                                {employee.employee}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {employee.sickDays > 0
                                  ? employee.sickDays +
                                    " of " +
                                    employee.allowed
                                  : 0}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Grid>
              );
            })}
        </Grid>
      )}
    </Grid>
  );
};

export default SickDays;
