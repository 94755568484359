import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  RadioGroup,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const CreateAdminBlocks = () => {
  const [submitSpinner, setSubmitSpinner] = useState("");
  const [staffData, setStaffData] = useState([]);
  // const [username, setUsername] = useState(cookies.get('ADPusername'));
  // const [password, setPassword] = useState(cookies.get('ADPpassword'));

  const {
    register,
    handleSubmit,
    // formState: { errors },
    // control,
    // setError,
    // getValues,
    // setValue,
    // clearErrors,
  } = useForm();

  // 114041290;
  // N5Y 5K7;
  //useeffect to get data from fetch

  useEffect(async () => {
    const fetchData = async () => {
      const res = await fetch("/api/get-clinician-data", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();
      console.log("here0", data);

      setStaffData(data);
    };
    await fetchData();
    console.log("here", staffData);
  }, []);

  const [siteDataContext] = useSiteDataApi();

  const onSubmit = async (data) => {
    let bodyData = data;
    const selectedStaff = Object.keys(data.staff)
      .filter((key) => data.staff[key])
      .map((key) => {
        const staffMember = staffData.find(
          (staff) => staff.shortUsername === key
        );
        return {
          username: key,
          location: staffMember ? staffMember.location : undefined,
        };
      });

    data.staff = selectedStaff;

    setSubmitSpinner("Creating blocks...");

    // cookies.set('ICSusername', username, { path: '/', secure: true, sameSite: 'none' });
    // cookies.set('ICSpassword', password, { path: '/', secure: true, sameSite: 'none' });
    try {
      const res = await fetch("/api/create-admin-blocks", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
        body: JSON.stringify(bodyData),
      });

      const data = await res.json();

      if (data) {
        // if (data === 'Error requesting pickup, please submit manually.') {
        //   console.log('got data', data);
        //remove the quotes from the string
        //replace \n with an actual new line
        setSubmitSpinner(
          JSON.stringify(data).replace(/['"]+/g, "").replace(/\\n/g, "\n")
        );
        // }
      }
    } catch (error) {
      console.log("error requesting pickup", error);
      // setSubmitResult('Error requesting pickup, please submit manually.');
    }
  };

  return (
    <Grid m={3} container>
      <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
        <Typography
          textAlign="center"
          variant="h4"
          gutterBottom
          style={{ marginBottom: "30px" }}
        >
          Create Admin Block
        </Typography>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item container justifyContent="space-between" sm={8}>
            <Grid item xs={5} sm={6}>
              <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                <label htmlFor="login">Clinician(s)</label>
              </div>
            </Grid>
            <Grid item xs={7} sm={6} className="selectbox">
              <FormGroup>
                {staffData.length > 0
                  ? staffData.map((staff) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register(`staff.${staff.shortUsername}`)}
                            name={`staff.${staff.shortUsername}`}
                            value={{
                              username: staff.shortUsername,
                              location: staff.location,
                            }}
                          />
                        }
                        label={staff.employee}
                        key={staff.shortUsername}
                      />
                    ))
                  : null}
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item container justifyContent="space-between" sm={8}>
            <Grid item xs={5} sm={6}>
              <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                <label htmlFor="login">Date</label>
              </div>
            </Grid>
            <Grid item xs={7} sm={6} className="selectbox">
              <TextField
                {...register("date")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid item container justifyContent="space-between" sm={8}>
            <Grid item xs={5} sm={6}>
              <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                <label htmlFor="login">Event Label</label>
              </div>
            </Grid>
            <Grid item xs={7} sm={6} className="selectbox">
              <TextField {...register("eventLabel")} label="Event Label" />
            </Grid>
          </Grid>

          <Grid item container justifyContent="center" sm={12}>
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                style={{ width: "100%" }}
                endIcon={<Box />}
              >
                Create Admin Block
              </Button>
              {submitSpinner ? (
                <Box m={3}>
                  <div style={{ textAlign: "center" }}>
                    {submitSpinner === "Creating admin blocks..." ? (
                      <CircularProgress />
                    ) : null}

                    <br />
                    <Typography>{submitSpinner}</Typography>
                    {submitSpinner ===
                    "Error creating admin blocks, please contact Chad."
                      ? null
                      : null}
                  </div>
                </Box>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default CreateAdminBlocks;
