import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Tooltip,
  // InputAdornment, Grid, TextField, Button
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import React, { useState, useEffect } from "react";
import SickDays from "./sections/sub-components/SickDays.js";
import VacationRequest from "./sections/sub-components/VacationRequest.js";
// import { DateRangeCalendar } from '@mui/x-date-pickers/DateRangeCalendar';

import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className="tabpanelmark"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    key: index,
  };
}
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TimeOff = () => {
  const [siteDataContext] = useSiteDataApi();

  const [username, setUsername] = React.useState(cookies.get("username"));
  const [value, setValue] = React.useState(0);
  const [timeOffData, settimeOffData] = useState();

  useEffect(() => {
    setUsername(cookies.get("username"));
    async function fetchData() {
      await fetchtimeOffData();
    }
    fetchData();
  }, []);

  const fetchtimeOffData = async () => {
    try {
      const res = await fetch("/api/timeoff", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      let data = await res.json();

      if (data) {
        //loop through data keep original data but add new property with an array of timeOff years and the count of entries for that year using lodash countBy

        // let obj = _.countBy(arr, (rec) => {
        //   return rec.score >= 65 ? 'pass' : 'fail';
        // });
        // console.log(obj);
        // { fail: 2, pass: 1 }

        // console.log("timeoff data", data);
        settimeOffData(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };

  return (
    <Box m={3} className="referralPage">
      {timeOffData ? (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="fullwidth tabs"
            >
              <Tab label="Sick Days" {...a11yProps(0)} key="0" />
              <Tab label="Vacation" {...a11yProps(1)} key="1" />
              {/* <Tab label="Flex Time" {...a11yProps(1)} key="2" /> */}
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <SickDays username={username} timeOffData={timeOffData} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <VacationRequest username={username} timeOffData={timeOffData} />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <FlexTime username={username} timeOffData={timeOffData} />
          </TabPanel> */}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading Timeoff data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TimeOff;
