import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useSiteDataApi } from '../../utilities/SiteDataProvider';

export default function ReturnModal({ open, ADPreturnDetails, handleClose, username, password }) {
  console.log('ADPreturnDetails', ADPreturnDetails);
  //   const { open, ADPreturnDetails, handleClose } = props;
  const { amount, healthcard_four, invoice_number, patient } = ADPreturnDetails.ADPreturnDetails;
  const [modalContent, setModalContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [patientName, setPatientName] = useState('');
  const [siteDataContext] = useSiteDataApi();
  const handleReturn = async () => {
    const parts = patient + '_' + invoice_number;
    const filename = parts.replace(' ', '_') + '.xml';
    const fileWithPath = '/home/nodejs/apps/staff.truehearinghealth.com/temp_xml_files/' + filename;

    try {
      const res = await fetch('/api/ADPDetailsparse', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': siteDataContext.thhcsfrtoken,
        },
        body: JSON.stringify({ path: fileWithPath, patient: patient }),
      });

      const data = await res.json();
      // console.log('return data', data);

      console.log('data', data.data);
      setModalContent(data.data);
      setFileName(data.fileName);
      setPatientName(data.patientName);

      // const adpRow;

      // 2022610,1003454558,0530,10733-D,26092022,26092022,,,HA0000939,2249289878,L,1,-1500,-500,-1000,
    } catch (error) {
      console.log('error parsing adp details', error);
      // setSubmitResult('Error requesting pickup, please submit manually.');
    }
  };

  const processReturn = async () => {
    setModalContent('submitting');
    try {
      const res = await fetch('/api/ADPProcessReturn', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': siteDataContext.thhcsfrtoken,
        },
        body: JSON.stringify({ content: modalContent, fileName, patientName }),
      });

      const data = await res.json();
      // console.log('return data', data);

      console.log('data', data);
      setModalContent(data);

      // const adpRow;

      // 2022610,1003454558,0530,10733-D,26092022,26092022,,,HA0000939,2249289878,L,1,-1500,-500,-1000,
    } catch (error) {
      console.log('error parsing adp details', error);
      // setSubmitResult('Error requesting pickup, please submit manually.');
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        {modalContent === '' ? (
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center">
              Are You Sure You Want to Return ${amount} to ADP for {patient}?
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center">
              Healthcard: {healthcard_four}
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center">
              Invoice #: {invoice_number}
            </Typography>
            <Grid container spacing={3} mt={3} alignItems="center" justifyContent="center">
              <Grid item>
                <Button onClick={handleClose} variant="outlined" color="primary">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                {' '}
                <Button variant="contained" color="primary" onClick={() => handleReturn()}>
                  Yes
                </Button>
              </Grid>
            </Grid>
          </>
        ) : modalContent === 'submitting' ? (
          <Box>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '30vh' }}>
              <Grid item xs={3}>
                <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
                <br />
                <Typography style={{ textAlign: 'center' }}>Submitting return to ADP...</Typography>
              </Grid>
            </Grid>
          </Box>
        ) : modalContent.includes('has been uploaded') ? (
          <Box>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '30vh' }}>
              <Grid item xs={3}>
                <br />
                <Typography style={{ textAlign: 'center' }}>{modalContent}</Typography>
                <Button onClick={handleClose} variant="outlined" color="primary">
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Typography>The following content will be submitted to ADP. Do you wish to proceed?</Typography>
            <div style={{ border: '1px solid #000', padding: '5px', margin: '20px' }}>{modalContent}</div>
            <Grid container spacing={3} mt={3} alignItems="center" justifyContent="center">
              <Grid item>
                <Button onClick={handleClose} variant="outlined" color="primary">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                {' '}
                <Button variant="contained" color="primary" onClick={() => processReturn()}>
                  Yes
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
