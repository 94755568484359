import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { useSiteDataApi } from '../utilities/SiteDataProvider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import defaultPicture from '../assets/empty-profile.png';
import linkedInIcon from '../assets/linkedin.jpg';
const libraries = ['places'];
const containerStyle = {
  width: '100%',
  height: '80vh',
};

const ClinicMap = () => {
  const [siteDataContext] = useSiteDataApi();
  const [center, setCenter] = useState({
    lat: 43.01385459999999,
    lng: -81.2446123,
  });
  const [searchPin, setSearchPin] = useState({});
  const [poi, setPoi] = useState([
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ]);
  const [clinicMarkers, setClinicMarkers] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [mapChange, setMapChange] = useState(0);
  const [infoWindow, setInfoWindow] = useState({});
  const [searchMarkerArray, setSearchMarkerArray] = useState([]);
  // const autocompleteRef = useRef();

  const options = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    maxZoom: 20,
  };
  function createKey(location) {
    return location.lat + location.lng + Math.random();
  }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    //TODO: put this in .env
    libraries,
    googleMapsApiKey: 'AIzaSyA7amQ7le3PheRiLzG4VVA105O6xNFXjbQ',
  });
  const [, setMap] = React.useState(null);

  function togglePOI() {
    if (poi[0].stylers[0].visibility === 'off') {
      setPoi([
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
      ]);
      console.log('changed poi');
    } else {
      setPoi([
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ]);
      console.log('changed poi back');
    }
    setMapChange(mapChange + 1);
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    async function fetchData() {
      await fetchMapData();
    }
    fetchData();
  }, []);

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  const addSearchIcon = () => {
    if (searchResult !== null) {
      setSearchMarkerArray([...searchMarkerArray, searchPin]);
      setSearchPin({});
      setCenter(center);
    }
  };

  function onPlaceChanged() {
    if (searchResult !== null) {
      const place = searchResult.getPlace();
      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();

      setCenter({ lat, lng });
      setSearchPin({ lat, lng });
    } else {
      alert('Please enter text');
    }
  }

  const fetchMapData = async () => {
    console.log('starting map data fetch');
    try {
      await fetch('/api/get-map-data', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': siteDataContext.thhcsfrtoken,
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const locationData = data.map((location) => {
            location.lat = parseFloat(location.lat);
            location.lng = parseFloat(location.lng);

            return location;
          });
          const cluster = (
            <MarkerClusterer options={options} key="one">
              {(clusterer) =>
                locationData.map((location) => (
                  <Marker
                    key={createKey(location)}
                    position={location}
                    clusterer={clusterer}
                    label={location.google_name}
                    onClick={() => {
                      setInfoWindow(location);
                    }}
                  />
                ))
              }
            </MarkerClusterer>
          );

          setClinicMarkers(cluster);
          // return userid;
        });
    } catch (error) {
      console.log('error getting map data', error);
    }
  };
  return (
    <Box m={3}>
      {isLoaded ? (
        <Grid container>
          <Grid item sm={8} xs={12}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={13}
              key={mapChange}
              options={{ mapTypeControl: false, styles: poi }}
              // onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={() => setInfoWindow(null)}
            >
              {searchPin.lat ? (
                <Marker
                  key="searchpin"
                  icon={'http://maps.google.com/mapfiles/ms/icons/green.png'}
                  position={{
                    lat: searchPin.lat,
                    lng: searchPin.lng,
                  }}
                />
              ) : null}
              {searchMarkerArray.length
                ? searchMarkerArray.map((marker, key) => (
                    <Marker
                      key={key}
                      icon={'http://maps.google.com/mapfiles/ms/icons/green.png'}
                      position={{
                        lat: marker.lat,
                        lng: marker.lng,
                      }}
                    />
                  ))
                : null}
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                <input
                  type="text"
                  placeholder="Search for a place..."
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `340px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: 'absolute',
                    left: '5px',
                    top: '5px',
                  }}
                />
              </Autocomplete>
              <AddBoxIcon
                color="primary"
                style={{
                  width: `40px`,
                  height: `40px`,
                  position: 'absolute',
                  left: '345px',
                  top: '2px',
                }}
                onClick={() => {
                  addSearchIcon();
                }}
              />
              {clinicMarkers !== null ? clinicMarkers : null}
              {/* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>
            <Button onClick={togglePOI} variant="contained" color="primary">
              Toggle POI
            </Button>
          </Grid>

          <Grid item sm={4} xs={12}>
            {infoWindow?.google_name ? (
              <Box m={5}>
                <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
                  {infoWindow.google_name} - {infoWindow.rating} ({infoWindow.user_ratings_total} ratings)
                </Typography>
                <Typography>
                  <LocationOnIcon color="primary" style={{ marginBottom: '-6px' }} />
                  {infoWindow.formatted_address}
                </Typography>

                {infoWindow.clinicianshis?.length ? (
                  <div>
                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '40px' }}>Hearing Instrument Specialists ({infoWindow.clinicianshis.length})</Typography>
                    <Grid container direction="column" spacing={3}>
                      {infoWindow.clinicianshis.map((clinicians, key) => {
                        return (
                          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} key={key}>
                            <Grid item>
                              {clinicians.linkedinURL ? (
                                <a href={`${clinicians.linkedinURL}`} target="_blank" rel="noreferrer">
                                  <img src={`${clinicians.profilePicture ? clinicians.profilePicture : defaultPicture}`} alt="" width="100" height="100" />
                                </a>
                              ) : (
                                <img src={`${clinicians.profilePicture ? clinicians.profilePicture : defaultPicture}`} alt="" width="100" height="100" />
                              )}
                            </Grid>
                            <Grid item>
                              <Typography>
                                {clinicians.linkedinURL ? (
                                  <a href={`${clinicians.linkedinURL}`} target="_blank" rel="noreferrer">
                                    {clinicians.firstname} {clinicians.lastname} <img src={linkedInIcon} width="20" height="20" style={{ marginBottom: '-4px' }} alt="" />
                                  </a>
                                ) : (
                                  <>
                                    {clinicians.firstname} {clinicians.lastname}
                                  </>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                ) : null}
                {infoWindow.cliniciansaud?.length ? (
                  <div>
                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '40px' }}>Audiologists ({infoWindow.cliniciansaud.length})</Typography>
                    <Grid container>
                      {infoWindow.cliniciansaud.map((clinicians, key) => {
                        return (
                          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} key={key}>
                            <Grid item>
                              {clinicians.linkedinURL ? (
                                <a href={`${clinicians.linkedinURL}`} target="_blank" rel="noreferrer">
                                  <img src={`${clinicians.profilePicture ? clinicians.profilePicture : defaultPicture}`} alt="" width="100" height="100" />
                                </a>
                              ) : (
                                <img src={`${clinicians.profilePicture ? clinicians.profilePicture : defaultPicture}`} alt="" width="100" height="100" />
                              )}
                            </Grid>
                            <Grid item>
                              <Typography>
                                {clinicians.linkedinURL ? (
                                  <a href={`${clinicians.linkedinURL}`} target="_blank" rel="noreferrer">
                                    {clinicians.firstname} {clinicians.lastname} <img src={linkedInIcon} width="20" height="20" style={{ marginBottom: '-4px' }} alt="" />
                                  </a>
                                ) : (
                                  <>
                                    {clinicians.firstname} {clinicians.lastname}
                                  </>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                ) : null}
                {/* <Typography>{infoWindow.rating}</Typography> */}
                {/* <Typography>
                  <StarIcon style={{color:'#fbbc04'}}/><span style={{ fontWeight: 'bold', fontSize: '18px' }}>{infoWindow.rating}</span> ({infoWindow.user_ratings_total} ratings)
                </Typography> */}
                <Typography></Typography>
              </Box>
            ) : (
              <Box m={5}>
                <Typography>Click on a clinic to display more information.</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
            <br />
            <Typography style={{ textAlign: 'center' }}>Loading map...</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ClinicMap;
