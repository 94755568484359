import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import { SiteDataProvider } from './utilities/SiteDataProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <SiteDataProvider>
        <HelmetProvider>
          <Helmet>
            {/* <meta http-equiv="Content-Security-Policy" content="Content-Security-Policy: default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; object-src 'self' blob: data:; style-src 'self'; frame-src 'self' blob: data:;" /> */}
            <meta name="viewport" content="width=1024" />
          </Helmet>
        </HelmetProvider>
        <App />
      </SiteDataProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
