import { styled } from "@mui/material/styles";
import { Box, Grid, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

// import bodyParser from 'body-parser';

function replacer(key, value) {
  if (key === "source") return undefined;
  else return value;
}

var groupByTime = require("group-by-time");

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// let referralDataSortedByDate = [];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    key: index,
  };
}

const ReferralReport = () => {
  const [value, setValue] = React.useState(
    parseInt(cookies.get("activeClinic")) || 0
  );
  const [checked, setChecked] = React.useState(true);

  const [siteDataContext] = useSiteDataApi();

  const handleSwitchChange = (event, newValue) => {
    setChecked(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };

  const [referralData, setReferralData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchReferralData();
    }
    fetchData();
  }, []);

  const fetchReferralData = async () => {
    try {
      // local version
      // const res = await fetch('http://localhost:5004/get-referral-data', {});
      // let data = {};
      // data.returnData = await res.json();

      const res = await fetch("/api/get-referral-data", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        //sort the data a bit
        // let referralDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   referralDataSortedByDate['location'] = referral.location;
        //   return null;
        // });
        console.log("wee", data);
        const grouped = groupBy(
          data.returnData,
          (referral) => referral.location
        );

        const referralDataSortedByDate = Array.from(grouped);

        referralDataSortedByDate.push(["Total", data.returnData]);

        referralDataSortedByDate.map((clinic) => {
          clinic[2] = groupByTime(clinic[1], "entry_time", "week");
          clinic[3] = groupByTime(clinic[1], "entry_time", "day");
          return null;
        });
        // let weeksArray = [];
        let awesomeClinicList = [];
        //loop through each clinic, Adelaide / Chatham etc.
        referralDataSortedByDate.map((clinic, clinicKey) => {
          //loop through the weeks to get first week
          // let clinicObj = {};

          // clinicObj.clinicName = clinic[0];
          awesomeClinicList[clinic[0]] = [];
          // Object.entries(clinic[2]).forEach((day, k) => {
          //   weeksArray.push(day);
          // });
          // // weeksArray.reverse();
          // // let firstWeekDate = new Date(parseInt(weeksArray[0][0]));
          // weeksArray = [];
          // // console.log('firstweekDate', firstWeekDate);
          // // awesomeClinicList[clinicKey].weeks = [];
          Object.entries(clinic[2]).map((workWeek) => {
            let week = workWeek[0];
            awesomeClinicList[clinic[0]][week] = [];

            // entry[1].map((innerEntry) => {

            Object.entries(clinic[3]).map((workDay) => {
              let Physician = "";
              let Directmail = "";
              let Event = "";
              let Advertising = "";
              let Formerpatient = "";
              let Patient = "";
              let Other = "";
              let PhysicianCount = 0;
              let DirectmailCount = 0;
              let EventCount = 0;
              let AdvertisingCount = 0;
              let FormerpatientCount = 0;
              let PatientCount = 0;
              let OtherCount = 0;
              let AppointmentBookedCount = 0;
              let PhysicianStats = [];
              let DirectmailStats = [];
              let EventStats = [];
              let AdvertisingStats = [];
              let FormerpatientStats = [];
              let PatientStats = [];
              let OtherStats = [];
              let PatientsNoAppt = "";

              let workingDate = new Date(parseInt(workDay[0]));
              let workingWeek = new Date(parseInt(week));
              let dateDiff = (workingDate - workingWeek) / (1000 * 24 * 3600);
              // console.log('dateDiff', dateDiff);
              if (dateDiff < 7 && dateDiff >= 0) {
                awesomeClinicList[clinic[0]][week][workDay[0]] = [];
                //sort workDay[1] by age first

                workDay[1].map((referral) => {
                  if (referral.referrer_type === "Family physician") {
                    PhysicianCount++;
                    PhysicianStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Physician += referral.referral_source_name + '(' + referral.age + ')\n';
                  } else if (referral.referrer_type === "Direct mail") {
                    DirectmailCount++;
                    DirectmailStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Directmail += '(' + referral.age + ')';
                  } else if (referral.referrer_type === "Advertising") {
                    AdvertisingCount++;
                    AdvertisingStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Advertising += referral.referral_source_name + '(' + referral.age + ')\n';
                  } else if (referral.referrer_type === "Former patient") {
                    FormerpatientCount++;
                    FormerpatientStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Formerpatient += referral.referral_source_name + '(' + referral.age + ')\n';
                  } else if (referral.referrer_type === "Event") {
                    EventCount++;
                    EventStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Formerpatient += referral.referral_source_name + '(' + referral.age + ')\n';
                  } else if (referral.referrer_type === "Patient") {
                    PatientCount++;
                    PatientStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Patient += referral.referral_source_name + '(' + referral.age + ')\n';
                  } else if (referral.referrer_type === "Other") {
                    OtherCount++;
                    OtherStats.push({
                      age: referral.age,
                      source: referral.referral_source_name,
                    });
                    // Other += referral.referral_source_name + '(' + referral.age + ')\n';
                  } else if (referral.referrer_type === "") {
                    OtherCount++;
                    OtherStats.push({ age: referral.age, source: "Other" });
                    // Other += referral.referral_source_name + '(' + referral.age + ')\n';
                  }
                  if (referral.hasAppointment === 1) {
                    AppointmentBookedCount++;
                  } else {
                    PatientsNoAppt +=
                      "(" + referral.surname + ", " + referral.given_name + ")";
                  }
                  return null;
                });

                awesomeClinicList[clinic[0]][week][workDay[0]].PatientsNoAppt =
                  PatientsNoAppt;

                awesomeClinicList[clinic[0]][week][workDay[0]].push(workDay[1]);

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AppointmentBookedCount = AppointmentBookedCount;

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].PhysicianAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Physician =
                  Physician;
                awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianCount =
                  PhysicianCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianStats =
                  PhysicianStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .PhysicianStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]]
                        .PhysicianStats[key],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].PhysicianAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .PhysicianAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].PhysicianAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].PhysicianStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .PhysicianStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].DirectmailAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Directmail =
                  Directmail;
                awesomeClinicList[clinic[0]][week][workDay[0]].DirectmailCount =
                  DirectmailCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].DirectmailStats =
                  DirectmailStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].DirectmailStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .DirectmailStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].DirectmailStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].DirectmailAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .DirectmailStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  //sort the ages first
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].DirectmailAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .DirectmailStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );

                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].DirectmailAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .DirectmailAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].DirectmailAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].DirectmailStats[key] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .DirectmailStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][workDay[0]].EventAgesString =
                  "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Event = Event;
                awesomeClinicList[clinic[0]][week][workDay[0]].EventCount =
                  EventCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].EventStats =
                  EventStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].EventStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].EventStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].EventStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].EventAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]].EventStats[
                        key
                      ],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].EventAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .EventAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].EventAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].EventStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].EventStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AdvertisingAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Advertising =
                  Advertising;
                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AdvertisingCount = AdvertisingCount;
                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AdvertisingStats = AdvertisingStats;
                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].AdvertisingStats = _.groupBy(
                  awesomeClinicList[clinic[0]][week][workDay[0]]
                    .AdvertisingStats,
                  "source"
                );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]]
                    .AdvertisingStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].AdvertisingAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .AdvertisingStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].AdvertisingAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .AdvertisingAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].AdvertisingAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].AdvertisingStats[key] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .AdvertisingStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].FormerpatientAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Formerpatient =
                  Formerpatient;
                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].FormerpatientCount = FormerpatientCount;
                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].FormerpatientStats = FormerpatientStats;
                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].FormerpatientStats = _.groupBy(
                  awesomeClinicList[clinic[0]][week][workDay[0]]
                    .FormerpatientStats,
                  "source"
                );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]]
                    .FormerpatientStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].FormerpatientAges = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .FormerpatientStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].FormerpatientAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .FormerpatientAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].FormerpatientAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].FormerpatientStats[key] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .FormerpatientStats[key],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][
                  workDay[0]
                ].PatientAgesString = "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Patient =
                  Patient;
                awesomeClinicList[clinic[0]][week][workDay[0]].PatientCount =
                  PatientCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].PatientStats =
                  PatientStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].PatientStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].PatientStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].PatientStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].PatientAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]]
                        .PatientStats[key],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].PatientAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .PatientAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].PatientAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].PatientStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].PatientStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });

                awesomeClinicList[clinic[0]][week][workDay[0]].OtherAgesString =
                  "";
                awesomeClinicList[clinic[0]][week][workDay[0]].Other = Other;
                awesomeClinicList[clinic[0]][week][workDay[0]].OtherCount =
                  OtherCount;
                awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats =
                  OtherStats;
                awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats =
                  _.groupBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats,
                    "source"
                  );
                Object.keys(
                  awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats
                ).map((key, index) => {
                  awesomeClinicList[clinic[0]][week][workDay[0]].OtherAges =
                    _.sortBy(
                      awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats[
                        key
                      ],
                      [
                        function (o) {
                          return o.age;
                        },
                      ]
                    );
                  awesomeClinicList[clinic[0]][week][
                    workDay[0]
                  ].OtherAgesString =
                    awesomeClinicList[clinic[0]][week][workDay[0]]
                      .OtherAgesString +
                    awesomeClinicList[clinic[0]][week][
                      workDay[0]
                    ].OtherAges.map(function (elem) {
                      if (elem.age) {
                        return `(${elem.age})`;
                      } else {
                        return null;
                      }
                    });

                  awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats[
                    key
                  ] = _.sortBy(
                    awesomeClinicList[clinic[0]][week][workDay[0]].OtherStats[
                      key
                    ],
                    [
                      function (o) {
                        return o.age;
                      },
                    ]
                  );
                  return null;
                });
              }
              return null;
            });
            return null;
          });
          return null;
        });

        setReferralData(awesomeClinicList);
        console.log("reference", awesomeClinicList);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  let {
    PhysicianAgg,
    DirectmailAgg,
    AdvertisingAgg,
    EventAgg,
    FormerpatientAgg,
    PatientAgg,
    OtherAgg,
    BookedAgg,
  } = 0;
  let PatientsNoAppt = "";
  let {
    PhysicianAggTotal,
    DirectmailAggTotal,
    AdvertisingAggTotal,
    EventAggTotal,
    FormerpatientAggTotal,
    PatientAggTotal,
    OtherAggTotal,
    BookedAggTotal,
  } = 0;

  return (
    <Box m={3} className="referralPage">
      {"Chatham" in referralData ? (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="fullwidth tabs"
            >
              {Object.keys(referralData).map((key, count) => {
                return <Tab label={key} {...a11yProps(count)} key={count} />;
              })}
            </Tabs>
          </AppBar>
          {Object.keys(referralData).map((key, count) => {
            return (
              <TabPanel value={value} index={count} key={count}>
                <Grid container alignItems="center" flexDirection="column">
                  <Grid item>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Summary</Typography>
                      <AntSwitch
                        checked={checked}
                        onChange={handleSwitchChange}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Weekly</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                {Object.keys(referralData[key]).map((week, j) => {
                  // console.log('week', referralData[key][week]);
                  // console.log('week', week);
                  // console.log('week', Object.entries(referralData[key][week]));
                  if (Object.entries(referralData[key][week]).length) {
                    PhysicianAgg =
                      DirectmailAgg =
                      AdvertisingAgg =
                      EventAgg =
                      FormerpatientAgg =
                      PatientAgg =
                      OtherAgg =
                      BookedAgg =
                        0;
                    PatientsNoAppt = "";
                    return (
                      <div
                        key={j}
                        style={{ marginTop: "20px" }}
                        className={`${checked ? "weekShow" : "weekHide"}`}
                      >
                        <Typography gutterBottom>
                          Week of{" "}
                          {new Date(parseInt(week)).toLocaleDateString()}
                        </Typography>
                        <TableContainer component={Paper} key={j}>
                          <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="referral table"
                            className="referralTable"
                          >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell style={{ width: "13%" }}>
                                  Date
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "12%" }}
                                >
                                  Physician
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "15%" }}
                                >
                                  Direct mail
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "15%" }}
                                >
                                  Event
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Advertising
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Former patient
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Patient
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Other
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Total Referrals
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ width: "10%" }}
                                >
                                  Referrals w/ Appts
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(referralData[key][week]).map(
                                (referralDay, y) => {
                                  let referralProperty = referralDay[1];
                                  PhysicianAgg +=
                                    referralProperty["PhysicianCount"];
                                  DirectmailAgg +=
                                    referralProperty["DirectmailCount"];
                                  AdvertisingAgg +=
                                    referralProperty["AdvertisingCount"];
                                  EventAgg += referralProperty["EventCount"];
                                  FormerpatientAgg +=
                                    referralProperty["FormerpatientCount"];
                                  PatientAgg +=
                                    referralProperty["PatientCount"];
                                  OtherAgg += referralProperty["OtherCount"];
                                  BookedAgg +=
                                    referralProperty["AppointmentBookedCount"];
                                  PatientsNoAppt +=
                                    referralProperty["PatientsNoAppt"];

                                  return (
                                    <StyledTableRow
                                      key={y}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell>
                                        {referralDay[0] === 0
                                          ? 0
                                          : `${new Date(
                                              parseInt(referralDay[0])
                                            ).toLocaleDateString("en-US", {
                                              weekday: "long",
                                            })} (${new Date(
                                              parseInt(referralDay[0])
                                            ).toLocaleDateString()})`}
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty["PhysicianCount"] >
                                            0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "PhysicianStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["PhysicianCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty[
                                                "PhysicianCount"
                                              ] > 0
                                                ? `${referralProperty[
                                                    "PhysicianAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty[
                                              "DirectmailCount"
                                            ] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "DirectmailStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["DirectmailCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty[
                                                "DirectmailCount"
                                              ] > 0
                                                ? `${referralProperty[
                                                    "DirectmailAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty["EventCount"] >
                                            0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "EventStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["EventCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty["EventCount"] >
                                              0
                                                ? `${referralProperty[
                                                    "EventAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty[
                                              "AdvertisingCount"
                                            ] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "AdvertisingStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["AdvertisingCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty[
                                                "AdvertisingCount"
                                              ] > 0
                                                ? `${referralProperty[
                                                    "AdvertisingAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty[
                                              "FormerpatientCount"
                                            ] > 0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "FormerpatientStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["FormerpatientCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty[
                                                "FormerpatientCount"
                                              ] > 0
                                                ? `${referralProperty[
                                                    "FormerpatientAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty["PatientCount"] >
                                            0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "PatientStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["PatientCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty[
                                                "PatientCount"
                                              ] > 0
                                                ? `${referralProperty[
                                                    "PatientAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty["OtherCount"] >
                                            0 ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {JSON.stringify(
                                                  referralProperty[
                                                    "OtherStats"
                                                  ],
                                                  replacer
                                                )
                                                  .replace(/["':{}]/g, "")
                                                  .replaceAll("age", "")
                                                  .replaceAll("],", "]\n")}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            <Typography className="countCount">{`${referralProperty["OtherCount"]}`}</Typography>
                                            <Typography className="ageCount">
                                              {referralProperty["OtherCount"] >
                                              0
                                                ? `${referralProperty[
                                                    "OtherAgesString"
                                                  ].replaceAll(",", "")}`
                                                : null}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell align="center">
                                        {referralProperty["PhysicianCount"] +
                                          referralProperty["DirectmailCount"] +
                                          referralProperty["EventCount"] +
                                          referralProperty["AdvertisingCount"] +
                                          referralProperty[
                                            "FormerpatientCount"
                                          ] +
                                          referralProperty["PatientCount"] +
                                          referralProperty["OtherCount"]}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className={
                                          referralProperty["PhysicianCount"] +
                                            referralProperty[
                                              "DirectmailCount"
                                            ] +
                                            referralProperty["EventCount"] +
                                            referralProperty[
                                              "AdvertisingCount"
                                            ] +
                                            referralProperty[
                                              "FormerpatientCount"
                                            ] +
                                            referralProperty["PatientCount"] +
                                            referralProperty["OtherCount"] >
                                          referralProperty[
                                            "AppointmentBookedCount"
                                          ]
                                            ? "notAllBooked"
                                            : null
                                        }
                                      >
                                        <Tooltip
                                          enterTouchDelay={0}
                                          leaveTouchDelay={0}
                                          title={
                                            referralProperty["PhysicianCount"] +
                                              referralProperty[
                                                "DirectmailCount"
                                              ] +
                                              referralProperty["EventCount"] +
                                              referralProperty[
                                                "AdvertisingCount"
                                              ] +
                                              referralProperty[
                                                "FormerpatientCount"
                                              ] +
                                              referralProperty["PatientCount"] +
                                              referralProperty["OtherCount"] >
                                            referralProperty[
                                              "AppointmentBookedCount"
                                            ] ? (
                                              <div
                                                style={{
                                                  whiteSpace: "pre-line",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {
                                                  referralProperty[
                                                    "PatientsNoAppt"
                                                  ]
                                                }
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          }
                                        >
                                          <Box>
                                            {
                                              referralProperty[
                                                "AppointmentBookedCount"
                                              ]
                                            }
                                          </Box>
                                        </Tooltip>
                                      </TableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}

                              <StyledTableRow className="totalRowAgg">
                                <TableCell className="totalRow">
                                  Total
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {PhysicianAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {DirectmailAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {EventAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {AdvertisingAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {FormerpatientAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {PatientAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {OtherAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {PhysicianAgg +
                                    DirectmailAgg +
                                    EventAgg +
                                    AdvertisingAgg +
                                    FormerpatientAgg +
                                    PatientAgg +
                                    OtherAgg}
                                </TableCell>
                                <TableCell align="center" className="totalRow">
                                  {BookedAgg}
                                </TableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    );
                  }
                  return null;
                })}
                <div
                  className={`totalTable ${checked ? "weekHide" : "weekShow"}`}
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="referral table"
                      className="referralTable referralTableTotal"
                    >
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell style={{ width: "13%" }}>
                            Week
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "12%" }}
                          >
                            Physician
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "15%" }}
                          >
                            Direct mail
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "15%" }}
                          >
                            Event
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Advertising
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Former patient
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Patient
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Other
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Total Referrals
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ width: "10%" }}
                          >
                            Referrals w/ Appts
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(referralData[key]).map((week, j) => {
                          // console.log('week', referralData[key][week]);
                          PhysicianAggTotal =
                            DirectmailAggTotal =
                            AdvertisingAggTotal =
                            EventAggTotal =
                            FormerpatientAggTotal =
                            PatientAggTotal =
                            OtherAggTotal =
                            BookedAggTotal =
                              0;

                          Object.entries(referralData[key][week]).map(
                            (referralDay, y) => {
                              // console.log(referralDay);
                              let referralProperty = referralDay[1];
                              PhysicianAggTotal +=
                                referralProperty["PhysicianCount"];
                              DirectmailAggTotal +=
                                referralProperty["DirectmailCount"];
                              EventAggTotal += referralProperty["EventCount"];
                              AdvertisingAggTotal +=
                                referralProperty["AdvertisingCount"];
                              FormerpatientAggTotal +=
                                referralProperty["FormerpatientCount"];
                              PatientAggTotal +=
                                referralProperty["PatientCount"];
                              OtherAggTotal += referralProperty["OtherCount"];
                              BookedAggTotal +=
                                referralProperty["AppointmentBookedCount"];
                              return null;
                            }
                          );
                          return (
                            <StyledTableRow className="totalRowAgg" key={j}>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {new Date(parseInt(week)).toLocaleDateString()}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {PhysicianAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {DirectmailAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {EventAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {AdvertisingAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {FormerpatientAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {PatientAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {OtherAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                              >
                                {PhysicianAggTotal +
                                  DirectmailAggTotal +
                                  EventAggTotal +
                                  AdvertisingAggTotal +
                                  FormerpatientAggTotal +
                                  PatientAggTotal +
                                  OtherAggTotal}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ width: "10%" }}
                                className={
                                  PhysicianAggTotal +
                                    DirectmailAggTotal +
                                    EventAggTotal +
                                    AdvertisingAggTotal +
                                    FormerpatientAggTotal +
                                    PatientAggTotal +
                                    OtherAggTotal >
                                  BookedAggTotal
                                    ? "notAllBooked"
                                    : null
                                }
                              >
                                {BookedAggTotal}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading referral data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ReferralReport;
