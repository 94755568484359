import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Tooltip,
  // InputAdornment, Grid, TextField, Button
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Availability = () => {
  const [siteDataContext] = useSiteDataApi();

  const [availabilityData, setAvailabilityData] = useState([]);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchavailabilityData();
    }
    fetchData();
  }, []);

  const fetchavailabilityData = async () => {
    try {
      // local version
      // const res = await fetch('http://localhost:5004/get-referral-data', {});
      // let data = {};
      // data.returnData = await res.json();

      const res = await fetch("/api/availability", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        //sort the data a bit
        // let availabilityDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   availabilityDataSortedByDate['location'] = referral.location;
        //   return null;
        // });
        // console.log('wee', availabilityDataSortedByDate);
        console.log("data", data);
        //for each data element, loop inside that array, and get each unique locationName property and push it to setUniqueLocations. the data object looks like: data["2023-07-02"][0].locationName
        let uniqueLocations = [];
        for (const [key, value] of Object.entries(data)) {
          value.map((location) => {
            if (!uniqueLocations.includes(location.locationName)) {
              uniqueLocations.push(location.locationName);
            }
            return null;
          });
        }

        setUniqueLocations(uniqueLocations);
        setAvailabilityData(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box m={3} className="referralPage">
      {availabilityData ? (
        <div style={{ marginTop: "20px" }}>
          <Typography
            style={{ textAlign: "center" }}
            gutterBottom
            component="h6"
            variant="h6"
          >
            Availability Report
          </Typography>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="referral table"
              className="referralTable"
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center" style={{ width: "13%" }}>
                    Week
                  </StyledTableCell>
                  {uniqueLocations?.map((location, i) => {
                    return (
                      <StyledTableCell
                        align="center"
                        style={{ width: "13%" }}
                        key={i}
                      >
                        {location}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.entries(availabilityData).map((week, y) => {
                  return (
                    <StyledTableRow
                      key={y}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell align="center">{week[0]}</TableCell>
                      {week[1].map((location, i) => {
                        return (
                          //add a tooltip to each cell that shows the FTEs
                          <TableCell
                            align="center"
                            className={
                              location.utilization < 0.5 ? "notAllBooked" : ""
                            }
                            key={i}
                          >
                            {Math.round(location.utilization * 100)}% <br />
                            <span style={{ fontSize: "11px" }}>
                              ({location.FTEs} FTEs)
                            </span>
                            <br />
                            {Math.round((location.minutesBooked / 60) * 100) /
                              100}{" "}
                            /{" "}
                            {Math.round(
                              (location.minutesAvailable / 60) * 100
                            ) / 100}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading availability data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Availability;
