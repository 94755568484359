import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { FileUploader } from 'react-drag-drop-files';
import React, { useState, useEffect } from 'react';
import { PDFDocument, degrees } from 'pdf-lib';
import download from 'downloadjs';
import { useSiteDataApi } from '../utilities/SiteDataProvider';
import axios from 'axios';
import { people } from './sections/variables/people.js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function isInt(value) {
  var x = parseFloat(value);
  return !isNaN(value) && (x | 0) === x;
}
function base64ToArrayBuffer(base64) {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

const SignatureMaker = () => {
  const fileTypes = ['PDF'];
  const [pdfInfo, setPdfInfo] = useState([]);
  const [username, setUsername] = useState(cookies.get('username') || '???');
  const [scale, setScale] = useState(50);
  const [initialPageRotation, setInitialPageRotation] = useState(0);
  const [zoom, setZoom] = useState(cookies.get('zoom') || 15);
  const [pdfLength, setPDFLength] = useState(1);
  const [pdfPage, setPDFPage] = useState(0);
  const [xPos, setxPos] = useState(50);
  const [yPos, setyPos] = useState(50);
  const [addingSig, setAddingSig] = useState('');
  const [addingSigSig, setAddingSigSig] = useState('');
  const [parsingFile, setParsingFile] = useState(0);
  const [pdfData, setPDFData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(null);
  const [downloadFile, setDownloadFile] = useState(false);
  const [siteDataContext] = useSiteDataApi();

  let fileReader;

  const handleFileChosen = async (file) => {
    setFileName(file.name);
    setParsingFile(1);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsArrayBuffer(file);
  };

  const handleFileRead = async () => {
    setPDFData(fileReader.result);
    // setPDFdoc(pdfByte);
  };

  useEffect(() => {
    setUsername(cookies.get('username'));
    console.log('setting suername to:', username);
  }, []);

  useEffect(() => {
    async function asyncLoad() {
      if (pdfData) {
        setError(null);
        try {
          const pdfDoc = await PDFDocument.load(pdfData);
          const pages = pdfDoc.getPages();
          setPDFLength(pages.length);
          const pdfBytes = new Uint8Array(pdfData);
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          const docUrl = URL.createObjectURL(blob);
          setPdfInfo(docUrl);
          setParsingFile(2);
          console.log('set the pdf info');
        } catch (error) {
          setParsingFile(0);
          setError("This is a protected PDF. (1) Open the PDF, (2) Print it to 'Microsoft Print to PDF' (3) Upload that file you save instead");
          console.log('protected pdf', error);
        }
      }
      if (addingSig !== '') {
      }
    }
    asyncLoad();
  }, [pdfData]);

  useEffect(() => {
    async function asyncLoad() {
      if (addingSig !== '') {
        let pdfBytes;
        const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true });
        const signatureArrayBuffer = base64ToArrayBuffer(addingSigSig);
        const jpgImage = await pdfDoc.embedPng(signatureArrayBuffer);
        // console.log('pdfDoc', pdfDoc);
        try {
          const pages = pdfDoc.getPages();

          const currentPage = pages[pdfPage];
          setPDFLength(pages.length);
          if (pdfPage > pages.length) {
            setPDFPage(0);
          }

          console.log('blah', { width: currentPage.getWidth(), height: currentPage.getHeight() });
          const rotationAngle = currentPage.getRotation().angle;
          if (rotationAngle !== 0) {
            //  transform: rotate(45deg);
            setInitialPageRotation(rotationAngle);
            currentPage.setRotation(degrees(0));
          }

          const jpgDims = jpgImage.scale((scale / 100) * 2);
          let computedX, computedY;
          computedX = currentPage.getWidth() * (xPos / 100) - jpgDims.width / 2;
          computedY = currentPage.getHeight() - (currentPage.getHeight() * (yPos / 100) - jpgDims.height / 2);
          // if (rotationAngle === 0) {
          // } else {
          //   computedX = currentPage.getWidth() * (xPos / 100) - jpgDims.height / 2;
          //   computedY = currentPage.getHeight() * (yPos / 100) - jpgDims.width / 2;
          // }

          console.log('jpgDims', { computedX, computedY });
          currentPage.drawImage(jpgImage, {
            x: computedX,
            y: computedY,
            width: jpgDims.width,
            height: jpgDims.height,
            rotate: degrees(rotationAngle),
          });
        } catch (error) {
          console.log('protected pdf', error);
        }
        if (addingSig !== '' && !downloadFile) {
          pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: 'application/pdf' });
          const docUrl = URL.createObjectURL(blob);
          setPdfInfo(docUrl);
          setParsingFile(2);
        }
        if (downloadFile) {
          console.log('download requested');
          await pdfDoc.saveAsBase64();
          const pages = pdfDoc.getPages();
          const currentPage = pages[pdfPage];
          currentPage.setRotation(degrees(initialPageRotation));
          let pdfBytesTwo = await pdfDoc.saveAsBase64();

          await axios.post('/api/signature', JSON.stringify({ file: pdfBytesTwo, fileName: fileName, username, signer: addingSig }), { headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': siteDataContext.thhcsfrtoken } }).then((res) => {
            console.log(res);
          });
          // pdfBytes = await pdfDoc.save();

          const linkSource = `data:application/pdf;base64,${pdfBytesTwo}`;
          const downloadLink = document.createElement('a');
          const fileSave = `${fileName.replace('.pdf', '')}-signed.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileSave;
          downloadLink.click();
          currentPage.setRotation(degrees(0));

          // download(pdfBytes, `${fileName.replace('.pdf', '')}-signed.pdf`, 'application/pdf');
          setDownloadFile(false);
          setParsingFile(0);
          // setAddingSig(false);
          // setAddingSigSig(false);
        }
      }
    }
    asyncLoad();
  }, [addingSig, addingSigSig, scale, xPos, yPos, pdfPage, downloadFile, zoom, parsingFile]);

  const addSignature = async (person) => {
    setAddingSig(person.name);
    setAddingSigSig(person.signature);
  };

  const removeSignature = async () => {
    setAddingSig('');
    setDownloadFile(false);
    const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true });
    const pages = pdfDoc.getPages();
    setPDFLength(pages.length);

    const pdfBytes = new Uint8Array(pdfData);
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const docUrl = URL.createObjectURL(blob);
    setPdfInfo(docUrl);
    setParsingFile(2);
  };

  return (
    <Box m={3} className="signaturePage">
      {!isInt(parsingFile / 2) ? (
        <Box>
          <Grid container spacing={0} direction="column" style={{ padding: '20px', minHeight: '100px' }}>
            <Grid item xs={3}>
              <CircularProgress />
              <br />
              <Typography>Validating file...</Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <FileUploader
          multiple={false}
          fileOrFiles={null}
          onTypeError={(err) => alert(err)}
          handleChange={(file) => {
            handleFileChosen(file);
          }}
          name="file"
          types={fileTypes}
        />
      )}
      {error ? <div style={{ padding: '20px', margin: '20px', border: '1px solid #ff0000', fontSize: '20px' }}>{error}</div> : null}
      {parsingFile === 2 ? (
        <Grid container spacing={2}>
          <Grid sm={8} item>
            <iframe id="iframeID" title="Add Signature to PDF" src={`${pdfInfo}#toolbar=0&zoom=${zoom}`} type="application/pdf" style={{ marginTop: '10px', width: '100%', height: '700px' }} />
          </Grid>
          <Grid sm={4} container item direction="row" mt={1} spacing={2}>
            <Grid container item spacing={2} sm={12}>
              {!addingSig ? (
                <Grid container spacing={2} direction="row" item sm={12} alignItems="center" justifyContent="center">
                  {people.map((person, key) => {
                    return (
                      <Grid item key={key}>
                        <Button variant="contained" color="primary" onClick={() => addSignature(person)}>
                          Add {person.name}'s Sig
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Grid container item spacing={2} sm={12} alignItems="center" justifyContent="center" direction="column">
                  <Grid item>
                    <Typography textAlign="center">Adding Signature</Typography>
                    <Typography variant="h5" textAlign="center">
                      {addingSig}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => removeSignature()}>
                      Cancel Signature
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => setDownloadFile(true)}>
                      Download File
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {addingSig !== '' ? (
              <Grid container item xs={12} direction="column">
                <Grid item xs={2} container aria-label="scale-container">
                  <Typography id="input-slider" gutterBottom>
                    Page
                  </Typography>
                  <Grid container item xs={12} mb={2} spacing={3}>
                    {Array.apply(null, Array(pdfLength)).map((x, i) => (
                      <Grid item key={i}>
                        <Button
                          variant={`${pdfPage === i ? 'outlined' : 'contained'}`}
                          key={i}
                          onClick={() => {
                            console.log('i', i);
                            setPDFPage(i);
                          }}
                        >
                          {i + 1}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography id="input-slider" gutterBottom>
                    Document Zoom
                  </Typography>
                  <Slider
                    defaultValue={zoom}
                    aria-label="Zoom"
                    valueLabelDisplay="on"
                    value={zoom}
                    min={1}
                    max={100}
                    onChange={(e) => {
                      cookies.set('zoom', e.target.value);
                      setZoom(e.target.value);
                    }}
                  />
                  <Typography id="input-slider" gutterBottom>
                    Scale
                  </Typography>
                  <Slider defaultValue={50} min={0} max={100} aria-label="Scale" valueLabelDisplay="on" value={scale} onChange={(e) => setScale(e.target.value)} />
                </Grid>
                <Grid item xs={2} container aria-label="leftright-container">
                  <Typography id="input-slider" gutterBottom>
                    Left / Right
                  </Typography>
                  <Slider defaultValue={50} aria-label="leftright" min={0} max={100} valueLabelDisplay="on" value={xPos} onChange={(e) => setxPos(e.target.value)} />
                </Grid>
                <Grid item xs={2} container aria-label="updown-container">
                  <Typography id="input-slider" gutterBottom>
                    Up / Down
                  </Typography>
                  <Slider defaultValue={50} aria-label="updown" min={0} max={100} valueLabelDisplay="on" value={yPos} onChange={(e) => setyPos(e.target.value)} />
                </Grid>
              </Grid>
            ) : (
              <Grid item sm={12}></Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default SignatureMaker;

//convert signature to string

// const bytes = new Uint8Array(pdfData);
// const blob = new Blob([bytes], { type: 'application/pdf' });
// const docUrl = URL.createObjectURL(blob);
// setPdfInfo(docUrl);

// const pdfBytes = await pdfDoc.saveAsBase64();

// setPDFdoc(await PDFDocument.load(base64ToArrayBuffer(pdfBytes)));
// // const pdfDoc = await PDFDocument.load(existingPdfBytes);

// const bytes = new Uint8Array(pdfBytes);
// const blob = new Blob([bytes], { type: 'application/pdf' });
// const docUrl = URL.createObjectURL(blob);
// setPdfInfo(docUrl);
// console.log('pdfBytes', pdfBytes);
// const pdfBytes = await pdfDoc.save();
// download(pdfBytes, 'sample.pdf', 'application/pdf');

// const pdfBytes = await pdfDoc.save();
// download(pdfBytes, 'pdf-lib_modification_example.pdf', 'application/pdf');

// const formData = new FormData();
// // const content = fileReader.result;
// formData.append('file', file);
// await axios.post('/api/signature-processing', formData, { headers: { 'X-CSRF-Token': siteDataContext.thhcsfrtoken } }).then((res) => {
//   console.log(res);
// });
// const handleFileChosen = async (file) => {
//   setParsingFile(true);
//   const formData = new FormData();
//   // const content = fileReader.result;
//   formData.append('file', file);
//   await axios.post('/api/signature-processing', formData, { headers: { 'X-CSRF-Token': siteDataContext.thhcsfrtoken } }).then((res) => {
//     console.log(res);
//   });

//   setParsingFile(false);
// };
