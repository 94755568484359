import React, { useState } from 'react';
const SiteDataContext = React.createContext();

export const SiteDataProvider = ({ children }) => {
  const siteDataApi = useState({ thhcsfrtoken: '' });
  return <SiteDataContext.Provider value={siteDataApi}>{children}</SiteDataContext.Provider>;
};

export function useSiteDataApi() {
  const apiContext = React.useContext(SiteDataContext);
  if (apiContext === undefined) {
    throw new Error('useSiteDataApi must be used within SiteDataApiProvider');
  }
  return apiContext;
}
