import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Tooltip,
  // InputAdornment, Grid, TextField, Button
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CircularProgress from '@mui/material/CircularProgress';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { useSiteDataApi } from '../utilities/SiteDataProvider';
const cookies = new Cookies();

// import bodyParser from 'body-parser';

function replacer(key, value) {
  if (key === 'source') return undefined;
  else return value;
}

var groupByTime = require('group-by-time');

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

// let annualsDataSortedByDate = [];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    key: index,
  };
}

const AnnualReport = () => {
  const [value, setValue] = React.useState(parseInt(cookies.get('activeClinic')) || 0);
  const [checked, setChecked] = React.useState(true);

  const [siteDataContext] = useSiteDataApi();

  const handleSwitchChange = (event, newValue) => {
    setChecked(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set('activeClinic', newValue, { path: '/', secure: true, sameSite: 'none' });
  };

  const [annualsData, setAnnualsData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchAnnualsData();
    }
    fetchData();
  }, []);

  const fetchAnnualsData = async () => {
    try {
      // local version
      // const res = await fetch('http://localhost:5004/get-referral-data', {});
      // let data = {};
      // data.returnData = await res.json();

      const res = await fetch('/api/annuals', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        //sort the data a bit
        // let annualsDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   annualsDataSortedByDate['location'] = referral.location;
        //   return null;
        // });
        // console.log('wee', annualsDataSortedByDate);
        console.log('data', data);
        const grouped = groupBy(data.returnData[0], (upgrade) => upgrade.location);

        const annualsDataSortedByDate = Array.from(grouped);
        console.log('annualsDataSortedByDate', annualsDataSortedByDate);
        const coolArray = [];
        annualsDataSortedByDate.map((clinic) => {
          coolArray[clinic[0]] = clinic[1];
        });
        setAnnualsData(coolArray);
        console.log('reference', coolArray);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Box m={3} className="referralPage">
      {'Chatham' in annualsData ? (
        <>
          <AppBar position="static">
            <Tabs value={value} variant="fullWidth" onChange={handleChange} indicatorColor="secondary" textColor="inherit" aria-label="fullwidth tabs">
              {Object.keys(annualsData).map((key, count) => {
                return <Tab label={key} {...a11yProps(count)} key={count} />;
              })}
            </Tabs>
          </AppBar>
          {Object.keys(annualsData).map((key, count) => {
            return (
              <TabPanel value={value} index={count} key={count}>
                <div style={{ marginTop: '20px' }}>
                  <Typography style={{ textAlign: 'center' }} gutterBottom component="h6" variant="h6">
                    Annuals
                  </Typography>
                  <Typography>Keep in mind, hearing loss reported is not necessarily accurate if Normal. Blueprint was not set correctly at the beginning so some people with hearing loss are incorrectly reported as "normal"</Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="referral table" className="referralTable">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell align="center" style={{ width: '13%' }}>
                            Days Since Last Test
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '12%' }}>
                            Patient Name
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '12%' }}>
                            Age
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '15%' }}>
                            Last Test Date
                          </StyledTableCell>
                          <StyledTableCell align="center" style={{ width: '15%' }}>
                            Hearing Loss (L / R)
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {annualsData[key].map((annualDay, y) => {
                          // console.log('annualsData', annualDay);
                          return (
                            <StyledTableRow
                              key={y}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center" className={annualDay.daysSinceLastTest > 365 ? 'notAllBooked' : ''}>
                                {annualDay.daysSinceLastTest}
                              </TableCell>
                              <TableCell align="center">
                                <Box>{annualDay.given_name + ' ' + annualDay.surname}</Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box>{annualDay.age}</Box>
                              </TableCell>
                              <TableCell align="center">
                                {new Date(annualDay.lastTestDate).toLocaleDateString('en-US', {
                                  // weekday: 'long',
                                })}
                              </TableCell>
                              <TableCell align="center" className={annualDay.severity_desc_left !== 'Normal' && annualDay.severity_desc_right !== 'Normal' ? 'notAllBooked' : ''}>
                                <Box>
                                  {annualDay.severity_desc_left} / {annualDay.severity_desc_right}
                                </Box>
                              </TableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
            <br />
            <Typography style={{ textAlign: 'center' }}>Loading annuals data...</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AnnualReport;
