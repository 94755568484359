import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { v4 as uuidv4 } from "uuid";
// import { useNavigate } from 'react-router-dom';

// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from "@react-oauth/google";

import {
  Box,
  Grid,
  Drawer,
  CircularProgress,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import {
  AccountTree,
  Analytics,
  ArrowCircleUp,
  AutoGraph,
  Autorenew,
  CalendarMonth,
  Cancel,
  Check,
  ChevronLeft,
  CreateOutlined,
  Engineering,
  HdrAuto,
  Inventory,
  LocalShipping,
  Map,
  Menu,
  Percent,
  Person,
  ShoppingBasket,
  TableChart,
  Timer,
  ZoomIn,
} from "@mui/icons-material/";
import { Main, DrawerHeader, AppBar } from "./components/DrawerComponent.js";
// import ExpenseSubmit from './components/ExpenseSubmit.js';
import ReferralReport from "./components/ReferralReport.js";
import NoRecalls from "./components/NoRecalls.js";
import UpgradeReport from "./components/UpgradeReport.js";
import AnnualReport from "./components/AnnualReport.js";
import Availability from "./components/Availability.js";
import HAOrderReport from "./components/HAOrders.js";
import Calendar from "./components/Calendar.js";
import ADPChecker from "./components/ADPChecker.js";
import Timeoff from "./components/Timeoff.js";
import WSIBReport from "./components/WSIBReport.js";
import SalesReport from "./components/SalesReport.js";
import ZoomReport from "./components/ZoomReport.js";
import PerformanceReport from "./components/PerformanceReport.js";
import SignatureMaker from "./components/SignatureMaker.js";
import InventoryComponent from "./components/Inventory.js";
import Accounting from "./components/Accounting.js";
import ClinicMap from "./components/ClinicMap.js";
import FormerPatient from "./components/FormerPatient.js";
import ICSpickupScheduler from "./components/ICSpickupScheduler.js";
import { useSiteDataApi } from "./utilities/SiteDataProvider";
// import Transcribe from "./components/Transcribe.js";

import Cookies from "universal-cookie";
import CreateAdminBlocks from "./components/CreateAdminBlocks";

const cookies = new Cookies();

// import dotenv from 'dotenv';
// dotenv.config('../.env');

const drawerWidth = 240;

const App = () => {
  const nonce = useRef(uuidv4());
  const [step, setStep] = useState(cookies.get("activeTab") || 0);

  const [, setSiteDataContext] = useSiteDataApi();

  // const navigate = useNavigate();

  // function handleTabClick(tab) {
  //   navigate(`/${tab}`);
  // }

  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [googleReLogin, setGoogleReLogin] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [stepComponent, setStepComponent] = React.useState(<ADPChecker />);

  // const [loggedInGoogle, setLoggedInGoogle] = React.useState({ email: 'contact@truehearinghealth.com' });
  const [loggedInGoogle, setLoggedInGoogle] = React.useState(false);

  const adminUsers = [
    "contact@truehearinghealth.com",
    "tfield@truehearinghealth.com",
    "bfield@truehearinghealth.com",
    "kfryday-field@truehearinghealth.com",
  ];

  // useLayoutEffect(() => {
  //   // isSignedIn
  //   setTimeout(() => {
  //     // console.log('blah', window);

  //     if (window.gapi?.auth2) console.log('julz', window.gapi?.auth2.getAuthInstance().isSignedIn.get());
  //   }, 5000);
  //   // if (window.gapi?.auth2) console.log('julz', window.gapi?.auth2.getAuthInstance());
  // }, [window.gapi?.auth2]);

  const handleFailure = (response) => {
    setGoogleReLogin(false);
    // console.log('response', response);
    if (window.gapi) {
      window.gapi.auth2.getAuthInstance().signOut();
    }
    setLoadingData(false);
    setLoggedInGoogle(false);
  };

  const handleLogin = async (googleData) => {
    setGoogleReLogin(false);
    setLoadingData(true);
    const res = await fetch("/api/verify", {
      method: "POST",
      body: JSON.stringify({
        token: googleData.credential,
        nonce: nonce.current,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();

    if (data) {
      const { valid, sessionToken1, sessionToken2, name } = data;
      // console.log('data', data);
      cookies.set("username", name.replace(" ", "").replace("%20", ""), {
        path: "/",
        secure: true,
        sameSite: "none",
      });

      const url = decodeURI(window.location.href);
      // if (url === "http://localhost:3000/") {
      //   setLoggedInGoogle({
      //     valid: true,
      //     name: "TrueHearing Health",
      //     email: "contact@truehearinghealth.com",
      //     payload: {},
      //   });
      // } else {
      //TODO: fix this
      if (valid) {
        setSiteDataContext((siteData) => {
          return {
            ...siteData,
            thhcsfrtoken: `${sessionToken1}.${sessionToken2}`,
          };
        });

        setLoggedInGoogle(data);
        setLoadingData(false);
        setTimeout(() => {
          setGoogleReLogin(true);
          let funDate = new Date();
          console.log("re logged in google", funDate);
        }, 1000000);
        // }, 1000 * 60);
        //1000000
      }
      // }
    }
    // store returned user somehow
  };
  const setCookie = (step) => {
    cookies.set("activeTab", step, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const list = [
    {
      title: "ADP Checker",
      component: <ADPChecker />,
      icon: <Check />,
      permission: "all",
    },
    {
      title: "Annuals",
      component: <AnnualReport />,
      icon: <Autorenew />,
      permission: "all",
    },
    {
      title: "Calendar",
      component: <Calendar />,
      icon: <CalendarMonth />,
      permission: "all",
    },
    {
      title: "ICS Pickup",
      component: <ICSpickupScheduler />,
      icon: <LocalShipping />,
      permission: "all",
    },
    {
      title: "Inventory",
      component: <InventoryComponent />,
      icon: <Inventory />,
      permission: "all",
    },
    {
      title: "Referrals",
      component: <ReferralReport />,
      icon: <TableChart />,
      permission: "all",
    },

    {
      title: "Missing Recalls",
      component: <NoRecalls />,
      icon: <Cancel />,
      permission: "all",
    },
    {
      title: "Sign",
      component: <SignatureMaker />,
      icon: <CreateOutlined />,
      permission: "all",
    },
    {
      title: "Timeoff",
      component: <Timeoff />,
      icon: <Timer />,
      permission: "all",
    },
    {
      title: "Upgrades",
      component: <UpgradeReport />,
      icon: <ArrowCircleUp />,
      permission: "all",
    },
    {
      title: "WSIB Patients",
      component: <WSIBReport />,
      icon: <Engineering />,
      permission: "all",
    },
    {
      title: "Availability",
      component: <Availability />,
      icon: <Percent />,
      permission: "admin",
    },
    {
      title: "Admin Block",
      component: <CreateAdminBlocks />,
      icon: <HdrAuto />,
      permission: "all",
    },
    {
      title: "Accounting",
      component: <Accounting />,
      icon: <AccountTree />,
      permission: "admin",
    },
    {
      title: "Clinic Map",
      component: <ClinicMap />,
      icon: <Map />,
      permission: "admin",
    },
    {
      title: "Former Patient",
      component: <FormerPatient />,
      icon: <Person />,
      permission: "admin",
    },
    {
      title: "HA Orders",
      component: <HAOrderReport />,
      icon: <ShoppingBasket />,
      permission: "admin",
    },
    {
      title: "Sales Report",
      component: <SalesReport />,
      icon: <AutoGraph />,
      permission: "admin",
    },
    {
      title: "Performance Report",
      component: <PerformanceReport />,
      icon: <Analytics />,
      permission: "admin",
    },
    {
      title: "Zoom Data",
      component: <ZoomReport />,
      icon: <ZoomIn />,
      permission: "admin",
    },
  ];
  const adminCount = list.filter((item) => item.permission === "admin").length;
  const nonAdminCount = list.filter((item) => item.permission === "all").length;

  useEffect(() => {
    // console.log('useeffect tunning');
    const url = decodeURI(window.location.href);
    // console.log('url', url);
    // if (
    //   url !== "https://staff.truehearinghealth.com/" ||
    //   url !== "http://localhost:3000/"
    // ) {
    // console.log('hasa url param');
    setOpen(false);
    const desiredURL = url.split("/").pop().replace("-", " ");
    // console.log('desiredURL', desiredURL);
    list.map((listitem, count) => {
      if (listitem.title === desiredURL) {
        // console.log('found it', listitem);
        setStep(count);
        setStepComponent(listitem.component);
      }
      return null;
    });
    // }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {/* {decodeURI(window.location.href) === "http://localhost:3000/" || */}
      {loggedInGoogle ? (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ alignItems: "space-between", background: "#202844" }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <Menu />
              </IconButton>
              <Box sx={{ marginRight: "5px", marginTop: "4px" }}>
                {list[step].icon}
                {console.log("step", step)}
              </Box>
              <Typography variant="h6" noWrap component="div">
                {list[step].title}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeft />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {list.map((listitem, i) => {
                if (listitem.permission !== "all") {
                  if (adminUsers.includes(loggedInGoogle.email)) {
                    return (
                      <div key={i}>
                        {i === nonAdminCount + 1 ? <Divider key={i} /> : null}
                        <ListItem
                          button
                          key={listitem.title + i}
                          onClick={() => {
                            setStep(i);
                            setOpen(false);
                            window.history.replaceState(
                              null,
                              listitem.title,
                              "/" + listitem.title.replace(" ", "-")
                            );
                            // handleTabClick(listitem.title);
                            setCookie(i);
                            setStepComponent(listitem.component);
                          }}
                        >
                          <ListItemIcon>{listitem.icon}</ListItemIcon>
                          <ListItemText primary={listitem.title} />
                        </ListItem>
                      </div>
                    );
                  }
                } else {
                  return (
                    <ListItem
                      button
                      key={listitem.title}
                      onClick={() => {
                        setStep(i);
                        setOpen(false);
                        window.history.replaceState(
                          null,
                          listitem.title,
                          "/" + listitem.title.replace(" ", "-")
                        );
                        setCookie(i);
                        setStepComponent(listitem.component);
                      }}
                    >
                      <ListItemIcon>{listitem.icon}</ListItemIcon>
                      <ListItemText primary={listitem.title} />
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>
          </Drawer>
          <Main open={open} className="maindiv">
            <DrawerHeader />
            {list[step].component}
          </Main>
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            {!loadingData ? (
              <GoogleLogin
                auto_select
                useOneTap={true}
                isSignedIn={true}
                onSuccess={handleLogin}
                onError={handleFailure}
                nonce={nonce.current}
                ux_mode="redirect"
              />
            ) : (
              <>
                <CircularProgress
                  style={{ display: "block", margin: "0 auto" }}
                />
                <br />
                <Typography style={{ textAlign: "center" }}>
                  Logging In...
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {/* {decodeURI(window.location.href) !== "http://localhost:3000/" && */}
      {googleReLogin ? (
        <GoogleLogin
          auto_select
          useOneTap={true}
          isSignedIn={true}
          onSuccess={handleLogin}
          onError={handleFailure}
          nonce={nonce.current}
          ux_mode="redirect"
        />
      ) : null}
    </Box>
  );
};

export default App;
