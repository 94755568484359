import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Tooltip,
  // InputAdornment, Grid, TextField, Button
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const InventoryComponent = () => {
  const [siteDataContext] = useSiteDataApi();

  const [inventoryData, setInventoryData] = useState([]);
  const [models, setModels] = useState([]);
  const [niceInventory, setNiceInventory] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await fetchInventoryData();
    }
    fetchData();
  }, []);

  const fetchInventoryData = async () => {
    try {
      const res = await fetch("/api/inventory", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      let data = await res.json();

      if (data) {
        // console.log('inventory data', data);
        // STOCK_AID_STATE_ORDERED = 0;
        // STOCK_AID_STATE_IN_STOCK = 1;
        // STOCK_AID_STATE_SENT_FOR_REPAIR = 2;
        // STOCK_AID_STATE_LOANED = 3;
        // STOCK_AID_STATE_SOLD = 4;
        // STOCK_AID_STATE_RETURNED_TO_STOCK = 5;
        // STOCK_AID_STATE_RETURNED_TO_MANUFACTURER = 6;
        // STOCK_AID_STATE_IN_TRANSIT = 7;
        // STOCK_AID_STATE_RESERVED = 8;

        // const noEmptySerial = data.returnData[0].filter((inventory) => {
        //   const { serial_number, state, vendor_name } = inventory;
        //   if (serial_number === '' || state === '4' || state === '6' || vendor_name !== 'ReSound Corporation') {
        //   } else {
        //     return inventory;
        //   }
        // });
        // console.log('noEmptySerial', noEmptySerial);

        const filteredArray = data.returnData[0].filter(
          (item) =>
            item.serial_number !== "" &&
            item.state !== "0" &&
            item.state !== "2" &&
            item.state !== "4" &&
            item.state !== "6" &&
            item.state !== "8" &&
            (item.vendor_name === "ReSound Corporation" ||
              item.vendor_name === "Starkey") &&
            !item.model_name.includes("Demo")
        );

        let resultArray = _(filteredArray)
          .groupBy("trip_name")
          .map((items, tripName) => {
            return {
              trip_name: tripName,
              model_name_counts: _(items)
                .groupBy("model_name")
                .map((modelNames, modelName) => {
                  return {
                    model_name: modelName,
                    count: _.size(modelNames),
                  };
                })
                .value(),
            };
          })
          .value();

        console.log(resultArray);

        resultArray = resultArray.map((item) => {
          item.model_name_counts.sort((a, b) => {
            if (a.model_name < b.model_name) {
              return -1;
            }
            if (a.model_name > b.model_name) {
              return 1;
            }
            return 0;
          });
          return item;
        });

        resultArray.forEach((model) => {
          let agg = 0;
          model.model_name_counts.map((model) => {
            agg += model.count;
          });
          model.model_name_counts.push({ model_name: "Total", count: agg });
        });

        setInventoryData(resultArray);
        const modelNames = [];
        resultArray.forEach((location) => {
          location.model_name_counts.forEach((model) => {
            if (!modelNames.includes(model.model_name)) {
              modelNames.push(model.model_name);
            }
          });
        });
        modelNames.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });

        setModels(modelNames);
        // const grouped = noEmptySerial.reduce((entryMap, e) => entryMap.set(e.trip_name, [...(entryMap.get(e.trip_name) || []), e]), new Map());
        // const grouped = groupBy(noEmptySerial, (inventory) => inventory.trip_name);

        // var grouped = _.mapValues(_.groupBy(noEmptySerial, 'trip_name'));

        // grouped.map()

        // console.log('grouped', grouped);
        // setInventoryData(grouped);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box m={3} className="referralPage">
      {inventoryData ? (
        <Grid width="100%" display="flex" justifyContent="center" container>
          <Grid item width="100%" display="flex" justifyContent="center">
            <Typography
              variant="h5"
              sx={{ margin: "0 0 30px", width: "100%", textAlign: "center" }}
            >
              Available Inventory (Not Reserved/Demo)
            </Typography>
          </Grid>
          <Grid item>
            <TableContainer sx={{ width: 900, clear: "both" }}>
              <Table
                sx={{ minWidth: 900, maxWidth: 900, width: 900 }}
                size="small"
                aria-label="referral table"
                className="referralTable"
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Clinic</StyledTableCell>
                    {models.map((modelName) => (
                      <StyledTableCell key={modelName} align="center">
                        {modelName}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {inventoryData.map((location) => (
                    <TableRow key={location.trip_name}>
                      <TableCell component="th" scope="row">
                        {location.trip_name}
                      </TableCell>
                      {models.map((modelName, key) => {
                        const modelCount = location.model_name_counts.find(
                          (model) => model.model_name === modelName
                        );

                        return (
                          <TableCell align="center" key={key}>
                            {modelCount ? modelCount.count : 0}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                  <StyledTableRow
                    sx={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
                  >
                    <TableCell component="th" scope="row">
                      Total
                    </TableCell>
                    {models.map((modelName, key) => {
                      const totalModelCounts = inventoryData.reduce(
                        (acc, curr) => {
                          const model = curr.model_name_counts.find(
                            (model) => model.model_name === modelName
                          );
                          return acc + (model ? model.count : 0);
                        },
                        0
                      );
                      return (
                        <TableCell align="center" key={key}>
                          {totalModelCounts}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading Inventory data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default InventoryComponent;
