import { Box, Checkbox, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment_timezone from "moment-timezone";
moment_timezone.tz.setDefault("America/New_York");

// import { createRequire } from 'module'; // Bring in the ability to create the 'require' method
// const require = createRequire(import.meta.url); // construct the require method
// const cliniciansJson = require('../../clinicians.json');

const cookies = new Cookies();

const localizer = momentLocalizer(moment_timezone);

const brianIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/VZzAwBrUhn6hUeE";
const joshIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/gDnsuEUK5yxlKUF";
const juliaIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/q1sbktbhTh5cCYQ";
const kaylaIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/JgNf1fo4pHpcM6Z";
const jessicaIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/kZ63KHiOxhXo44G";
const milkaIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/RWOHIXIUq5V87cs";
const lauraIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/JdsZOG9blYfHrza";
const christineIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/jTja7dRaTPbZ1hO";
const kellyIcal =
  "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05fdHJ1/ZRRF3PNtktgI6pq";

const allCalendars = [
  { name: "Brian", calendar: brianIcal, username: "bfield" },
  { name: "Josh", calendar: joshIcal, username: "jwright" },
  { name: "Julia", calendar: juliaIcal, username: "jjanusz" },
  { name: "Kayla", calendar: kaylaIcal, username: "kbelanger" },
  { name: "Milka", calendar: milkaIcal, username: "milka" },
  { name: "Jessica", calendar: jessicaIcal, username: "jchester" },
  { name: "Laura", calendar: lauraIcal, username: "lcoates" },
  { name: "Christine", calendar: christineIcal, username: "cdeluca" },
  { name: "Kelly", calendar: kellyIcal, username: "kfenn" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    key: index,
  };
}

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const CalendarReport = () => {
  const [value, setValue] = React.useState(
    parseInt(cookies.get("activeClinic")) || 0
  );
  const [myEventsList, setMyEventsList] = React.useState([]);
  const [selectedCalendars, setSelectedCalendars] = React.useState(
    cookies.get("activeCalendar") || ""
  );

  const [siteDataContext] = useSiteDataApi();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };

  const [calendarData, setCalendarData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchCalendarData();
      return () => clearInterval(intervalId);
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000 * 60 * 10);
  }, []);

  const fetchCalendarData = async () => {
    const combinedCalendars = allCalendars
      .map(function (elem) {
        return elem.calendar;
      })
      .join("|");

    const res = await fetch(`/api/ical-parse?ical=${combinedCalendars}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": siteDataContext.thhcsfrtoken,
      },
    });
    const data = await res.json();

    const { appointments, ical } = data;
    console.log("ical", ical);
    const fullArray = {};
    let allEntries = ical.map((icalendar, icalKey) => {
      // icalendar[icalKey].clinician = allCalendars[icalKey].name;
      // const newArr = icalendar.map((e) => ({
      Object.keys(icalendar).forEach((item, index) => {
        icalendar[item].clinician = allCalendars[icalKey].username;
        fullArray[item] = icalendar[item];
      });
      return icalendar;
      // }));
      // console.log(newArr);
      // Object.keys(cally).forEach(function (item, index) {
      //   item[index].clinician = allCalendars[icalKey].name;
      // });
      //   return icalendar;
    });
    // const obj = Object.assign({}, ...allEntries);

    // var mergedArrays = [].concat.apply([], ical);
    function groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    }
    let vevents = [];
    for (var item of Object.keys(fullArray)) {
      vevents.push(fullArray[item]);
    }
    const grouped = groupBy(vevents, (event) => event.location);

    let calendarArray = Array.from(grouped);
    let clinicCalendars = [];
    calendarArray = calendarArray.map((clinic) => {
      if (clinic[0] !== undefined) clinicCalendars.push(clinic);
    });
    let copyCalendar = [];
    if (clinicCalendars) {
      let agemap = [];
      appointments[0].map((appointment) => {
        let { start_time, end_time, title, location, age, username } =
          appointment;
        let matcher =
          username +
          start_time.substring(0, 16) +
          end_time.substring(0, 16) +
          location;
        matcher = matcher.replace(/\s/g, "");
        // + title;
        agemap[matcher] = age;
      });
      console.log("agemap", agemap);

      clinicCalendars.map((clinic, key) => {
        let clinicname = clinic[0];
        copyCalendar[clinicname] = [];
        // console.log('appt0', appointments[0]);

        clinic[1].map((event, index) => {
          // console.log('event', event);
          let apptClientID = event.dtstart;
          let { start, end, summary, clinician, uid } = event;
          let eventlocation = event.location;
          let age = 0;
          let agecheck =
            event.clinician.toLowerCase() +
            moment
              .tz(start, "America/Toronto")
              .format()
              .replace("T", " ")
              .substring(0, 16) +
            moment
              .tz(end, "America/Toronto")
              .format()
              .replace("T", " ")
              .substring(0, 16) +
            eventlocation;
          agecheck = agecheck.replace(/\s/g, "");

          //  + summary.replace(/\s\(.*/g, '');
          // console.log('agecheck', agecheck);
          age = agemap[agecheck] || 0;
          // appointments[0].map((appointment) => {
          //   let { start_time, end_time, title, location } = appointment;

          //   // if (start_time.substring(0, 15) === dtstart.substring(0, 15) && end_time.substring(0, 15) === dtend.substring(0, 15) && eventlocation === location) {
          //   //   age = appointment.age;
          //   // }
          // });

          copyCalendar[clinicname].push({
            id: uid,
            resourceId: clinician,
            title: age !== 0 ? "[" + age + "] " + summary : "" + summary,
            start: new Date(start),
            end: new Date(end),
          });
          //   // events.push({ id: event.uid, title: event.summary, start: new Date(event.start), end: new Date(event.end) });
        });
      });

      // setMyEventsList((current) => [...current, copyCalendar]);
      setMyEventsList(copyCalendar);
    }
  };

  return (
    <Box m={3} className="referralPage">
      {"London" in myEventsList ? (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {allCalendars.map((clinician, key) => (
              <Grid
                container
                key={key}
                direction="row"
                alignItems="center"
                justifyContent="center"
                item
                sm={1}
                xs={12}
              >
                <Grid item>
                  <Checkbox
                    size="medium"
                    id={clinician.username}
                    checked={
                      cookies
                        .get("activeCalendar")
                        ?.includes(clinician.username) || false
                    }
                    onChange={(value) => {
                      let newCalendar = "";
                      if (value.target.checked === false) {
                        newCalendar = selectedCalendars.replace(
                          value.target.id,
                          ""
                        );
                        console.log("newcal", newCalendar);
                        setSelectedCalendars(newCalendar);
                        cookies.set("activeCalendar", newCalendar, {
                          path: "/",
                          secure: true,
                          sameSite: "none",
                        });
                      } else {
                        newCalendar = selectedCalendars + value.target.id;
                        setSelectedCalendars(newCalendar);
                        console.log("newcal else", newCalendar);
                        cookies.set("activeCalendar", newCalendar, {
                          path: "/",
                          secure: true,
                          sameSite: "none",
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ padding: "9px" }}>
                    {clinician.name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <AppBar position="static">
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="fullwidth tabs"
            >
              {Object.keys(myEventsList).map((key, count) => {
                return <Tab label={key} {...a11yProps(count)} key={count} />;
              })}
            </Tabs>
          </AppBar>
          {Object.keys(myEventsList).map((key, count) => {
            return (
              <TabPanel value={value} index={count} key={count}>
                <div style={{ marginTop: "20px" }}>
                  <Calendar
                    localizer={localizer}
                    events={myEventsList[key]}
                    resources={allCalendars
                      .filter((item) =>
                        selectedCalendars.includes(item.username)
                      )
                      .map((calendar) => {
                        return {
                          id: calendar.username,
                          title: calendar.name,
                        };
                        // }
                      })}
                    // resourceTitleAccessor="resource"
                    resourceAccessor={(event) => event.resourceId}
                    resourceIdAccessor={(event) => event.id}
                    resourceTitleAccessor={(resource) => resource.title}
                    // startAccessor="start"
                    // endAccessor="end"
                    style={{ height: "75vh" }}
                    formats={formats}
                    min={moment("7:00am", "h:mma").toDate()}
                    max={moment("7:00pm", "h:mma").toDate()}
                    defaultView="day"
                    views={["day", "work_week"]}
                    eventPropGetter={(event) => ({
                      className: event.title
                        .toLowerCase()
                        .trim()
                        .replace(/.*\(/gm, "")
                        .replace(/\)/gm, "")
                        .replace(/2dc/gm, "twodc")
                        .replace(/b&d/gm, "bandd"),
                    })}
                  />
                </div>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading calendar...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CalendarReport;
