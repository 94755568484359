import { styled } from "@mui/material/styles";
import { Box, Grid, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
const cookies = new Cookies();

function replacer(key, value) {
  if (key === "source") return undefined;
  else return value;
}

var groupByTime = require("group-by-time");
function dateDiffDays(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// let referralDataSortedByDate = [];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
    key: index,
  };
}

(function () {
  if (typeof Object.defineProperty === "function") {
    try {
      Object.defineProperty(Array.prototype, "sortBy", { value: sb });
    } catch (e) {}
  }
  if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

  function sb(f) {
    for (var i = this.length; i; ) {
      var o = this[--i];
      this[i] = [].concat(f.call(o, o, i), o);
    }
    this.sort(function (a, b) {
      for (var i = 0, len = a.length; i < len; ++i) {
        if (a[i] !== b[i]) return a[i] < b[i] ? -1 : 1;
      }
      return 0;
    });
    for (var i = this.length; i; ) {
      this[--i] = this[i][this[i].length - 1];
    }
    return this;
  }
})();

const NoRecalls = () => {
  const [value, setValue] = React.useState(
    parseInt(cookies.get("activeClinic")) || 0
  );
  const [checked, setChecked] = React.useState(true);

  const [siteDataContext] = useSiteDataApi();

  const handleSwitchChange = (event, newValue) => {
    setChecked(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cookies.set("activeClinic", newValue, {
      path: "/",
      secure: true,
      sameSite: "none",
    });
  };

  const [referralData, setReferralData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchReferralData();
    }
    fetchData();
  }, []);

  const fetchReferralData = async () => {
    try {
      const res = await fetch("/api/no-recalls", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": siteDataContext.thhcsfrtoken,
        },
      });

      let data = await res.json();
      console.log("norecall", data);
      if (data.returnData) {
        //sort the data a bit
        // let referralDataSortedByDate = [];
        // data.returnData.map((referral) => {
        //   referralDataSortedByDate['location'] = referral.location;
        //   return null;
        // });

        const grouped = groupBy(
          data.returnData,
          (noRecallPatient) => noRecallPatient.location
        );
        const referralDataSortedByDate = Array.from(grouped);

        referralDataSortedByDate.push(["All", data.returnData]);

        console.log("reference", referralDataSortedByDate);
        setReferralData(referralDataSortedByDate);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box m={3} className="referralPage">
      {referralData && referralData[2] ? (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="fullwidth tabs"
            >
              {referralData.map((key, count) => {
                return (
                  <Tab
                    label={
                      referralData[count][0] +
                      " (" +
                      referralData[count][1].length +
                      ")"
                    }
                    {...a11yProps(count)}
                    key={count}
                  />
                );
              })}
            </Tabs>
          </AppBar>
          {referralData.map((key, count) => {
            console.log("keyhere", key);
            return (
              <TabPanel value={value} index={count} key={count}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="wsibtable"
                    className="referralTable"
                  >
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell style={{ width: "10%" }}>
                          Patient
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "10%" }}>
                          Age
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "10%" }}>
                          Last Appointment
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {referralData[count][1].map((patient, j) => {
                        // const appointment = patient.history.split(';');
                        // let shouldHaveBilled = 0;
                        // let tsDate = '';
                        // const paid = patient.paid;
                        // console.log('appt', appointment);
                        // appointment.forEach((appt, key) => {
                        //   const details = appt.split(',');
                        //   if (details[0] === 'Treatment Start') {
                        //     //the went ahead with treatment
                        //     if (
                        //       tsDate === ''
                        //       // && (new Date() - new Date(new Date(details[1]))) / (1000 * 3600 * 24) < 365 * 4
                        //     ) {
                        //       // if (tsDate === '' && (new Date() - new Date(new Date(details[1]))) / (1000 * 3600 * 24) < 365 * 4) {
                        //       shouldHaveBilled = shouldHaveBilled + 1650;
                        //       let dateDiff = dateDiffDays(new Date(appointment[key].split(',')[1]), new Date());
                        //       let dateDiffTS = dateDiffDays(new Date(details[1]), new Date());
                        //       if (dateDiffTS > 90) {
                        //         shouldHaveBilled = shouldHaveBilled + 424;
                        //       }
                        //       if (dateDiff > 365) {
                        //         shouldHaveBilled = shouldHaveBilled + 1527.5;
                        //         console.log('annual should be billed');
                        //       }
                        //       if (dateDiff > 730) {
                        //         shouldHaveBilled = shouldHaveBilled + 1527.5;
                        //         console.log('annual 2 should be billed');
                        //       }
                        //       if (dateDiff > 1095) {
                        //         shouldHaveBilled = shouldHaveBilled + 1527.5;
                        //         console.log('annual 2 should be billed');
                        //       }
                        //     }
                        //     tsDate = new Date(details[1]);
                        //   }
                        // });
                        // if (tsDate === '') {
                        //   console.log('this pat', patient);

                        //   const firstAppt = patient.first_appt;
                        //   if (firstAppt) {
                        //     const dateDiff = dateDiffDays(new Date(firstAppt), new Date());

                        //     if (dateDiff > 365) {
                        //       shouldHaveBilled = shouldHaveBilled + 1527.5;
                        //     }
                        //     if (dateDiff > 730) {
                        //       shouldHaveBilled = shouldHaveBilled + 1527.5;
                        //     }
                        //     if (dateDiff > 1095) {
                        //       shouldHaveBilled = shouldHaveBilled + 1527.5;
                        //     }
                        //   }
                        // }

                        return (
                          <StyledTableRow>
                            <StyledTableCell>
                              {patient.given_name + " " + patient.surname}
                            </StyledTableCell>
                            <StyledTableCell>{patient.age}</StyledTableCell>
                            <StyledTableCell>
                              {new Date(
                                patient.latestAppt
                              ).toLocaleDateString()}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading Recall data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default NoRecalls;
