import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CircularProgress from '@mui/material/CircularProgress';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import { useSiteDataApi } from '../utilities/SiteDataProvider';

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const FormerPatient = () => {
  const [siteDataContext] = useSiteDataApi();

  const [referralData, setReferralData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await fetchReferralData();
    }
    fetchData();
  }, []);

  const fetchReferralData = async () => {
    try {
      const res = await fetch('/api/get-former-data', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': siteDataContext.thhcsfrtoken,
        },
      });

      const data = await res.json();

      if (data) {
        // console.log('data found', data);

        setReferralData(data);
        const grouped = groupBy(data.returnData[0], (patient) => patient.client_id);

        const referralDataSortedByDate = Array.from(grouped);

        const newGroupedPatients = referralDataSortedByDate.map((patient) => {
          const { first_name, last_name } = patient[1][0];
          patient[0] = `${first_name} ${last_name}`;
          patient[3] = `${last_name}, ${first_name}`;
          return patient;
        });

        setGroupedData(newGroupedPatients);
        // console.log('here you', newGroupedPatients);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const textSearch = (search) => {
    // console.log('search', groupedData);
    if (search.length >= 3) {
      const newData = groupedData.filter((patient) => patient[0].substring(0, search.length) === search.toUpperCase());
      const newDataTwo = groupedData.filter((patient) => patient[3].substring(0, search.length) === search.toUpperCase());
      setDisplayData(newData.concat(newDataTwo));
    } else {
      setDisplayData([]);
    }

    // const debouncedCheckCalendarLink = useDebouncedHook(filterPatientArray, 500);
  };

  // const filterPatientArray = async (name) => {

  // });

  return (
    <Box m={3} className="referralPage">
      {referralData ? (
        <Grid container alignItems="center" justifyContent="center">
          <Grid container justifyContent="center" alignItems="center" item>
            <Grid item sm={1}>
              <label>Patient Name:</label>
            </Grid>
            <Grid item sm={2}>
              <TextField onBlur={(e) => textSearch(e.target.value)} onChange={(e) => textSearch(e.target.value)} />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            {displayData.length ? (
              <TableContainer component={Paper} key={3} style={{ marginTop: '30px', width: '100%' }}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="referral table" className="formerPatientTable">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell style={{ width: '10%' }}>Name</StyledTableCell>
                      <StyledTableCell>DOB</StyledTableCell>
                      <StyledTableCell style={{ width: '50%' }}>Devices</StyledTableCell>
                      <StyledTableCell>Loss Left</StyledTableCell>
                      <StyledTableCell>Loss Right</StyledTableCell>
                      <StyledTableCell>Address</StyledTableCell>
                      <StyledTableCell>Phone</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {displayData.map((patient, y) => {
                      // console.log(patient);
                      const DOB = patient[1][0].age;
                      // const today = Date.now();
                      const { severity_desc_left, severity_desc_right, email, phone, address } = patient[1][0];
                      const deviceType = patient[1]?.map((device) => {
                        const {
                          vendor_name,
                          model_name,
                          serial_number,
                          // warranty_expiry_date,
                          delivery_time,
                          // returned_time
                        } = device;
                        return (
                          <div>
                            {/* // <Tooltip
                          //   enterTouchDelay={0}
                          //   leaveTouchDelay={0}
                          //   className="blah"
                          //   title={
                          //     <div style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>
                          //       {
                          //         serial_number
                          //         //  + ' ' + warranty_expiry_date + ' ' + delivery_time + ' ' + returned_time + ' ' + client_price
                          //       }
                          //     </div>
                          //   }
                          // > */}
                            {vendor_name + model_name + '(Purchased: ' + delivery_time + ') Serial #' + serial_number}
                          </div>
                          // </Tooltip>
                        );
                      });

                      return (
                        <StyledTableRow
                          key={y}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{patient[0]}</TableCell>
                          <TableCell>{DOB}</TableCell>
                          <TableCell>{deviceType}</TableCell>
                          <TableCell>{severity_desc_left}</TableCell>
                          <TableCell>{severity_desc_right}</TableCell>
                          <TableCell>{address}</TableCell>
                          <TableCell>{phone}</TableCell>
                          <TableCell>{email}</TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} />
            <br />
            <Typography style={{ textAlign: 'center' }}>Loading referral data...</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FormerPatient;
